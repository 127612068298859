{
  "country_code": "351",
  "toMiles": false,
  "neighborhoods": true,
  "currency_config_up": "EUR",
  "masks": {
    "phone": "000 000 000",
    "cell": "000 000 000",
    "secret_number": "000 000 000",
    "ssn_mask": "000000000",
    "zipcode": "0000-000",
    "date_mask": "dd/MM/yyyy",
    "date_mask_format_m": "dd/MM/yyyy",
    "date_mask_two": "dd/MM",
    "mi": "KM",
    "states": []
  },
  "sale_without_registration": "Venda sem cadastro",
  "i_dont_need_indentify_client": "Não preciso identificar o cliente",
  "uf": "UF",
  "order_code": "Código do pedido",
  "favorite_card_comment": "Ex: Meu cartão favorito",
  "finalize_order_comment": "Finalizar Pedido",
  "goodbye": "Ate mais!",
  "opened": "aberto",
  "optional": "opcional",
  "add_comment": "+Adicionar",
  "obs": "Observações",
  "my_orders": "meus pedidos",
  "tax_and_time_of_shipping": "taxa e tempo de entrega",
  "scheduling": "Encomendas",
  "scheduling2": "Agendamento",
  "close_order": "fechar pedido",
  "verify": "verificar",
  "add_more_items": "adicionar mais itens",
  "next": "Próximo",
  "cart_empty": "Carrinho Vazio",
  "edit_item": "editar item",
  "select_shipoing": "Escolha a Entrega",
  "take_out": "retirar no local",
  "pick_up_counter": "Retirar no Balcão",
  "cupom": "cupom",
  "active_your_cupom": "ativar seu cupom",
  "code_of_cupom": "código do cupom",
  "total": "total",
  "select_the_form_of_shipping": "Escolha a forma de entrega",
  "obrigatory": "obrigatório",
  "go_to_payment": "ir para pagamento",
  "pay_on_app": "pagar no app",
  "pay_on_shippingg": "pagar na entrega",
  "discont": "Desconto",
  "discount_comment": "Desconto",
  "payment_in_comment": "Pagamento em",
  "cash_payment": "pagamento em dinheiro",
  "payment_debit_card": "pagamento em cartão de débito",
  "payment_credit_card": "pagamento em cartão de crédito",
  "i_need_transhipment": "preciso de troco para",
  "i_not_need_transhipment": "Não preciso de troco",
  "secret_number_on_note": "CPF/CNPJ na nota",
  "search_products_by_name_or_description": "Pesquisar Produtos por Nome ou descrição",
  "outside_the_coverage_area": "fora da área de cobertura",
  "unavilable": "Indisponível",
  "avaible": "disponível",
  "product_unavilable": "produto indisponível",
  "unavilable_amount": "quantidade indisponível",
  "table_s": "mesas",
  "discont_of": "desconto de",
  "free_freight": "frete grátis",
  "free_freight_comment": "Frete Grátis",
  "covered_address": "Endereço Fora de Cobertura",
  "send_order": "enviar pedido",
  "you_are_blocked": "você está bloqueado",
  "enter_your_cell": "preencha o Celular/WhatsApp",
  "enter_your_email": "digite seu email",
  "enter_your_address": "digite seu endereço",
  "enter_you_ddd": "Preencha o Celular/WhatsApp com DDD",
  "minimum_unconceived_value": "valor mínimo não atingido",
  "finish_payment_pix": "finalizar pagamento via pix",
  "finish_payment": "finalizar pagamento",
  "my_cards": "meus cartões",
  "payment_online": "pagamento online",
  "payment_offline": "pagamento na entrega",
  "add_new_card": "adicionar novo cartão",
  "pay_on_delivery": "pagar na entrega",
  "i_need_change_for": "preciso de troco para",
  "i_dont_need_change": "não preciso de troco",
  "send_the_receipt": "envie o comprovante em nosso whatsapp para agilizar o seu atendimento",
  "copy_key": "copiar chave",
  "select_the_flag": "Selecione a bandeira",
  "select_the_flag_comment": "Selecione uma Bandeira",
  "cpf_on_the_receipt": "CPF/CNPJ na nota",
  "how_to_pay_with_pix": "Como pagar no PIX",
  "enter_cpf_generate_pix": "Digite seu CPF para gerar uma chave PIX",
  "enter_cpf": "Digite seu CPF",
  "enter_a_valid_cpf": "Insira um CPF Válido",
  "order_pending_payment": "pedido aguardando pagamento",
  "generate_pix_key": "Gerar chave PIX",
  "pix_copy_paste": "PIX Copia e cola",
  "copy_code": "copiar código",
  "pix_key_expired": "Sua chave PIX expirou. CLique no botão para gerar uma nova",
  "paste_this_code_your_bank": "Cole este código na opção PIX Copia e Cola do seu banco",
  "verify_pix_payment": "Verificar pagamento do PIX",
  "card_details": "dados do cartão",
  "name_on_the_card": "nome no cartão",
  "card_number": "número do cartão",
  "expiry": "validade",
  "card_nickname": "apelido do cartão (opcional)",
  "billing_adress": "endereço da cobrança",
  "zip_code_not_found": "CEP não encontrado",
  "adress_line_2": "complemento (opcional)",
  "order_will_be_delivered": "o pedido será entregue à",
  "to_consult": "À Consultar",
  "flavors_comment": "Sabores",
  "flavor_comment": "Sabor",
  "crust": "Borda",
  "add_on": "Complemento",
  "general_toppings": "complementos gerais",
  "single_crust": "borda única",
  "message_cvv_payment": "Digite o código CVV do seu Cartão para finalizar o pagamento",
  "code_back_of_your_card": "este código fica atrás do seu cartão",
  "complete_payment": "finalizar pagamento",
  "what_card_nickname": "qual apelido desse cartão",
  "use_cashback": "utilizar saldo cashback",
  "balance": "saldo",
  "indentify_yourself": "identifique-se",
  "whatsapp_number": "número do whatsapp",
  "number_comment": "Número",
  "date_of_birth": "data de nascimento",
  "place_order_necessary_information": "para realizar o pedido acima, é necessário o preenchimento dos dados acima",
  "order_slip_s": "Comandas",
  "zip_invalid": "CEP inválido",
  "no_active_orders": "sem pedidos ativos",
  "order_sucess": "pedido realizado com sucesso",
  "send_confirmation_whatsapp": "enviar confirmação via whatsapp",
  "select_delivery_date": "selecione uma data para entrega",
  "time_zone_is_different": "Indentificamos que seu fuso horário é diferente do fuso horário do estabelecimento, ao lado do horário estará o seu respectivo horário de recebimento",
  "delivery_time_between": "escolha um horário de entrega entre os periodos",
  "delivery_for_order": "escolha um horário de entrega para sua encomenda",
  "establishment_notes": "observações do estabelecimento",
  "no_more_avaible_slots_for_orders": "não temos mais vagas disponíveis para encomendas, entre em contato com nosso estabelecimento",
  "contact_establishment": "fale com o estabelecimento",
  "no_slots_avaible_for_orders_on_that_day": "não temos mais vagas para encomendas no dia",
  "please_select_another_date": "por favor, selecione outra data.",
  "continue": "continuar",
  "last_orders": "Últimos pedidos",
  "view_order": "ver pedido",
  "edit_addresses": "editar endereços",
  "addresses": "endereço",
  "add_new_addresses": "cadastrar novo endereço",
  "create_address_or_use_list_below": "crie um novo endereço ou use a lista abaixo",
  "more_information": "mais informações",
  "notification_permissions": "para receber notificações é necessário aceitar a permissão de notificações em seu dispositivo.",
  "opening_hours": "horário de funcionamento",
  "from_de": "De",
  "to": "até",
  "pay_online": "pagar online",
  "choose_this_option_secure_payment": "escolha esta opção para realizar o pagamento de forma segura através de nosso sistema online.",
  "choose_option_receiving": "prefere pagar ao receber seu pedido? Escolha esta opção.",
  "acess_your_payment_app": "acesse seu app de pagamentos",
  "qrcode_copy_paste_payment": "escaneie o QR code ou copie e cole o código do pagamento",
  "pay_credited_instantly": "pague e será creditado na hora",
  "enter your cpf": "Digte seu CPF",
  "n": "Nº",
  "payment_still_pending": "pagamento ainda pendente. Por favor tente novamente.",
  "confirmation_is_taking_too_long": "se a confirmação estiver demorando muito, clique no botão para continuar.",
  "use_delivery_address": "usar endereço de entrega",
  "state": "estado",
  "payment_meal_voucher": "pagamento em vale refeição",
  "payment_food_voucher": "pagamento em vale alimentação",
  "receipt_to_our_whatsapp": "envie o comprovante em nosso whatsapp para agilizar o seu atendimento",
  "total_to_transfer": "total a transferir",
  "type_of_key": "tipo de chave",
  "save_future_purchases": "salvar para compras futuras",
  "finalize_order": "finalizar pedido",
  "choose_single_crust": "escolha a borda única",
  "choose_single_crust_comment": "Escolha a Borda Única",
  "choose_crust_comment": "Escolha a Borda",
  "crust_without_filling": "sem borda",
  "choose_the": "Escolha a",
  "choose_the_o": "Escolha o",
  "complete_order_mandotory": "complete o seu pedido com os itens obrigatórios",
  "no_orders_moment": "sem pedidos no momento",
  "fee_comment": "Taxa",
  "pickup_only": "somente retirada no local",
  "local_pickup": "Retirada no Local",
  "loading_dates": "carregando datas",
  "choose_date": "escolher uma data",
  "see_full_list": "clique aqui para ver a lista completa de produtos",
  "no_products_found": "desculpe, nenhum produto encontrado",
  "size_unavilable": "tamanho indisponível",
  "edit_notes": "editar observações",
  "remove_item": "remover item",
  "point_camera_qrcode": "por favor aponte a sua câmera para o QRCode da mesa em que deseja realizar o pedido",
  "order_status": "status do pedido",
  "in_preparation": "em preparo",
  "canceled": "cancelado",
  "order_received": "pedido recebido",
  "pending_payment": "aguardando pagamento",
  "order_approved": "pedido aprovado",
  "order_preparation": "pedido em preparo",
  "order_canceled": "pedido cancelado",
  "order_out_for_delivery": "pedido saiu para entrega",
  "order_ready_for_pickup": "pedido pronto para retirada",
  "send_order_whatsapp": "enviar pedido via whatsapp",
  "reference_point": "ponto de referência",
  "house_corner": "Casa da esquina",
  "address_complement": "Complemento",
  "block_apt": "Bloco, Apto",
  "ave": "Av. Brasil",
  "date_usa": "dd/mm/yyyy",
  "choose_time": "escolha um horário",
  "minimum_quantity": "Quantidade minima",
  "maximum_quantity": "Quantidade maxima",
  "what_your_name": "Qual o seu nome",
  "open_new_order": "Abrir nova comanda",
  "new_order": "Nova Comanda",
  "repeat_order": "repetir pedido",
  "enter_name_your_order": "Insira um nome para sua comanda",
  "select_order": "Selecione uma comanda",
  "counter_and_delivery": "Balcão/Delivery",
  "discount_of": "desconto de",
  "applied": "aplicado",
  "addition": "Acréscimo",
  "change_address": "mudar endereço",
  "message_no_costumer_selected": "Nenhum cliente selecionado para este pedido, busque por um cliente cadastrado o cadastre um para poder prosseguir.",
  "search_customer": "buscar cliente",
  "add_customer": "cadastrar cliente",
  "outside_service_area": "FORA DA ÁREA DE COBERTURA",
  "free_comment": "Grátis",
  "free_comment_up": "GRÁTIS",
  "activate_coupon_comment": "Ative seu Cupom",
  "order_type": "tipo de pedido",
  "place_order": "fazer pedido",
  "open_register": "abrir caixa",
  "initial_balance": "Saldo Inicial R$",
  "initial_balance_n": "Saldo Inicial",
  "income": "entrada",
  "cash_register_status": "status do caixa",
  "cash_outflow": "saída",
  "cash_outflows": "saídas",
  "cod": "cód.",
  "close_cash_register": "fechar caixa",
  "cash_balance": "saldo dinheiro",
  "cashier_balance": "saldo caixa",
  "final_balance": "saldo final",
  "create_order_slip": "criar comanda",
  "change_tables": "trocar de mesas",
  "table_change": "troca mesa",
  "print_table": "imprimir mesa",
  "close_table": "encerrar mesa",
  "whatsapp": "WhatsApp",
  "client_comment": "Cliente",
  "no_registred_address": "nenhum endereço cadastrado",
  "none": "nenhum",
  "no_categories_available_at_moment": "Nenhuma categoria disponível para pedidos do tipo mesa no momento.",
  "check_admin_panel_categories_active": "Verifique no painel de administrador se as categorias estão ativas e disponíveis para pedidos de tipo mesa.",
  "search_name_comment": "Buscar Por Nome",
  "search_flavor_comment": "Buscar por Sabor",
  "waiting_service": "aguardando atendimento",
  "pause_comment": "Pausar",
  "unpause_comment": "Despausar",
  "order_buy": "pedir",
  "order_the_table": "pedir na mesa",
  "registration_comment": "Registrando",
  "change_comment": "Alterar",
  "one_more_comment": "Mais um",
  "customer": "clientes",
  "customer_name_comment": "Nome do cliente",
  "email_example_comment": "cliente@exemplo.com",
  "ssn_model_comment": "000.000.000-00",
  "number_model_comment": "(00) 00000-0000",
  "order_by": "Pedido por",
  "delivery_address": "Endereço de Entrega",
  "no_number": "sem número",
  "dont_know_my_zip": "Não sei meu CEP",
  "other_s": "Outras",
  "deliver_this_address": "Entregar neste endereço",
  "out_coverage_area": "fora da área de cobertura",
  "error_generating_qrcode": "Erro ao gerar QR Code. Por favor, tente novamente.",
  "finalize_appointment": "fechar agendamento",
  "payment_not_detected_check_banking": "Pagamento não detectado. por favor verifique o aplicativo do seu banco.",
  "error_processing_payment": "Erro ao processar o pagamento, por favor tente novamente.",
  "error_creating_customer": "Erro ao criar cliente. Por favor tente novamente.",
  "return_cancel": "Voltar/Cancelar",
  "return": "Voltar",
  "remaining": "faltam",
  "fee_discount": "Taxa/Desconto",
  "how_pay_pix": "Como pagar no PIX",
  "step": "passo",
  "payment_deadline": "o tempo para pagamento acaba em",
  "select_comment": "Selecione",
  "call_back_house": "Ex: Chamar na Casa dos Fundos",
  "change_for_comment": "Troco Para",
  "for": "para",
  "out for delivery": "saiu para entrega",
  "waiting_for_pickup": "aguardando retirada",
  "add_flavor": "adicionar sabor",
  "your_flavor": " seu sabor",
  "choose": "Escolha",
  "selected_date_is_earlier": "a data escolhida é anterior à atual. Por gentileza, escolha outro horário",
  "register_closing": "fechamento de caixa",
  "confirm_register_closing": "confirmar fechamento de caixa",
  "closing_insert_following": "ao realizar o fechamento, insira os seguintes valores na gaveta do seu caixa",
  "register_login": "login caixa",
  "deposit": "Depósito",
  "purpose": "finalidade",
  "list": "lista",
  "make_payment": "fazer pagamento",
  "uncancel_order": "descancelar pedido",
  "order_amount": "valor total do pedido",
  "print_the": "realizar impressão da",
  "next_flavor_comment": "Próximo Sabor",
  "choose_flavor_comment": "Escolha o Sabor",
  "quantity_unavaible": "Quantidade Indisponível",
  "complete_toppings_comment": "Complete os Complementos",
  "out_stock_toppings_comment": "Complementos Sem Estoque",
  "in_stock_comment": "Em Estoque",
  "the_flavors_comment": "os Sabores",
  "the_crust_comment": "as Bordas",
  "the_toppings_comment": "os Complementos",
  "mandatory_to_choose": "é obrigatório escolher",
  "time_closed_comment": "Horário Encerrado!",
  "change_less_purchase_comment": "Troco abaixo do valor da compra",
  "free_shipping_comment": "FRETE GRÁTIS",
  "consult_comment": "Consultar",
  "location_comment": "Local",
  "paid_online_comment": "Pago Online",
  "track_order": "Acompanhar Pedido",
  "order_comment": "Encomenda",
  "schedule": "Agendar",
  "closed_orders": "Fechado Encomendas",
  "scheduling_closed": "Agendamento Fechado",
  "order_name": "nome da comanda",
  "no_product_found": "nenhum produto encontrado",
  "items_found": "itens encontrados",
  "mandatory_items": "itens obrigatorios",
  "minimum": "minímo",
  "advance": "Avançar",
  "add_another_card": "adicionar outro cartão",
  "loading_page": "Carregando...",
  "enter_city": "Digite a cidade",
  "select_city": "Selecione a cidade",
  "enter_neighborhood": "Digite o bairro",
  "select_neighborhood": "Selecione o bairro",
  "enter_street_name": "Digite o nome da rua",
  "coupon_only_used_purchases": "Esse cupom só pode ser usado em vendas a partir de",
  "discount_coupon_greater_total": "O desconto no cupom é maior do que o valor total!",
  "add_to_order": "Adicionar para encomenda",
  "data_print": "Data de impressão",
  "generate_fiscal_note": "Realizar Emissão de nota fiscal da",
  "alert": {
    "unable_to_read": "Não foi possível ler a sua localização, por favor verifique se os serviços de localização não estão bloqueados.",
    "address_covarage_area": "O seu endereço está fora da nossa área de cobertura!",
    "register_open_24": "Há um caixa aberto há mais de 24 horas e será fechado.",
    "table_desactived": "Desculpe, esta mesa está atualmente desativada.",
    "moment_table_not_avaible": "No momento, esta mesa não está disponível para novos pedidos.",
    "table_not_avaible_new_orders": "No momento, esta mesa não está disponível para novos pedidos.",
    "order_already_been_completed": "O pedido anterior já foi concluído. Ainda está no restaurante ou gostaria de fazer um novo pedido para entrega?",
    "at_the_table": "Estou na mesa",
    "deliver_order": "Entregar pedido",
    "not_belong_this_table": "Este pedido não pertence a esta mesa.",
    "store_closed": "LOJA FECHADA!",
    "not_minimum_value": "O seu pedido não atingiu o valor mínimo de",
    "failed_validate_coupon": "Falha ao validar o cupão, por favor verifique a sua ligação!",
    "amount_not_include_delivery_fee": "Este valor total não inclui a taxa de entrega.",
    "coupon_used_starting_from": "Este cupão só pode ser utilizado para compras a partir de",
    "closed_delivery": "FECHADO PARA ENTREGA",
    "unable_create_customer": "Não foi possível criar o cliente. Por favor, tente novamente.",
    "selected_date_earlier_choose_later_date": "A data selecionada é anterior à data atual, por favor escolha uma data posterior.",
    "try_again_moment": "Algo inesperado aconteceu, por favor tente novamente em um momento.",
    "error_generate_qrcode": "Não foi possível gerar o QR Code. Por favor, tente novamente.",
    "payment_not_detected": "Pagamento não detectado. Por favor, verifique o seu aplicativo bancário.",
    "reloaded_page_message": "A página precisa ser recarregada!",
    "name_and_phone_required": "Nome e número de telefone são obrigatórios.",
    "enter_your_details": "Insira os seus dados",
    "enter_your_name": "Insira o seu nome",
    "enter_your_whatsapp": "Insira o seu Whatsapp",
    "enter_your_date_of_birth": "Insira a sua data de nascimento",
    "review_change_amount": "Revise o Valor do Troco!",
    "no_internet_connection": "Sem ligação à Internet",
    "add_another_card": "Adicionar outro cartão",
    "zip_invalid": "O código postal é inválido"
  },
  "calendar": {
    "weekdays": ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"],
    "months": [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ]
  },
  "timezones": [
    {
      "label": "Lisbon",
      "value": "Europe/Lisbon"
    },
    {
      "label": "Madeira",
      "value": "Atlantic/Madeira"
    },
    {
      "label": "Azores",
      "value": "Atlantic/Azores"
    }
  ],
  "unitSystem": "imperial",
  "distanceinkmto": "Distância em KM até",
  "distance": "Distância",
  "distance_added_successfully": "Distância adicionada com sucesso!",
  "the_distance_already_exists": "Essa distância ja existe",
  "distance_deleted_successfully": "Distância excluída com sucesso!",
  "update_shipping": "Atualizar frete",
  "tobeconfirmed": "A Consultar",
  "km": "KM",
  "time": "Tempo",
  "zelle": "Zelle",
  "twint": "Twint",
  "coin": "€",
  "currency_config": "eur",
  "ddi": "351",
  "date_format": "dd/MM/yyyy",
  "date_format_v2": "dd/MM",
  "welcome": "Bem-vindo",
  "description": "Descrição",
  "orders": "Pedidos",
  "order": "Pedido",
  "order_management": "Gestão de Pedidos",
  "new_feature": "Nova Funcionalidade",
  "work_waiters_taking": "Trabalha com empregados de mesa a receber pedidos?",
  "register_you_waiters_quickly": "Então registe os seus empregados de mesa e faça pedidos rapidamente.",
  "dont_work_waiters": "Não trabalho com empregados de mesa",
  "register_waiters": "Registar os meus empregados de mesa",
  "orders_were_not_received": "Pedidos que não foram recebidos.",
  "orders_appointments": "Pedidos/Agendamentos",
  "efficiency": "Eficiência",
  "incredible_agility": "Incrível agilidade na gestão de mesas e pedidos como nunca visto antes; este sistema é tudo o que sempre sonhou para ganhar mais dinheiro e organizar o seu restaurante.",
  "automation": "Automatização",
  "now_you_control": "Agora controla as mesas e os pedidos no seu restaurante automaticamente, sem erros ou trabalho extra para anotar os pedidos.",
  "qrcode_self_service": "Tecnologia de autoatendimento por código QR na mesa; não precisa de um empregado de mesa para receber os pedidos.",
  "self_service": "Autoatendimento",
  "customers_place_orders_themselves": "Com este autoatendimento, os seus clientes fazem os pedidos diretamente na mesa, e os pedidos são impressos automaticamente na sua cozinha na ordem em que são recebidos.",
  "zero_waste": "Desperdício zero",
  "indecipherable_handwriting": "Diga adeus às caligrafias indecifráveis, erros e perdas na preparação dos pratos. Mantenha tudo a funcionar suavemente e eficientemente no seu restaurante.",
  "closure": "Encerramento",
  "automatically_separates_calculates": "Liquidação rápida diretamente na mesa ou na caixa. O WhatsMenu separa e calcula automaticamente o que cada pedido consumiu, permitindo cobrar individualmente os clientes se saírem antes da mesa ser encerrada, garantindo transações suaves e sem erros.",
  "ordering_system": "O Sistema de Pedidos",
  "allows_your_customer": "Permite ao seu cliente escolher o dia e a hora para receber o pedido.",
  "allows_customer_they_wish": "Permite ao cliente escolher o dia e a hora que deseja receber o pedido.",
  "you_can_sell_24h": "Pode vender 24 horas por dia, 7 dias por semana, mesmo quando o seu estabelecimento está fechado.",
  "prevents_setbacks": "Previne contratempos, como o cliente estar indisponível para receber o pedido e o entregador fazer uma viagem em vão.",
  "advantages": "Vantagens",
  "configure_days_establishment": "Configure os dias e horários aceites pelo estabelecimento.",
  "customer_best_time_for_them": "O cliente escolhe a melhor hora para si.",
  "helps_improve": "Ajuda a melhorar a organização da logística de entregas.",
  "speak_with_consultant": "Fale agora com um consultor",
  "must_be_between": "O valor deve estar entre 0,49 e 0,99",
  "acess": "Acesso",
  "delete_addresses": "Eliminar endereços",
  "addresses_deleted": "Endereços eliminados com sucesso!",
  "control_of": "Controlo de",
  "error_deleting_addresses": "Erro ao eliminar endereços. Verifique a consola para mais detalhes.",
  "user": "Utilizador",
  "sucess_updated": "atualizado com sucesso",
  "something_wrong": "Algo correu mal",
  "talk_it_team": "Fale com a equipa de TI",
  "user_profile": "Perfil do utilizador",
  "profile": "Perfil",
  "not_registered": "Não registrado",
  "with_area_code": "Com código de área",
  "owner_cell_phone": "Telemóvel do proprietário",
  "owner_email_address": "Endereço de e-mail do proprietário",
  "full_name": "Nome completo",
  "register_customer": "Registar Cliente",
  "select_payment_gateway_for_user": "Selecione a plataforma de pagamento para o utilizador antes de prosseguir.",
  "internationalization": "Internacionalização",
  "asaas_account_disconnected": "Conta Asaas desconectada do perfil",
  "unable_disconnect_asaas_account": "Não foi possível desconectar a conta Asaas do perfil.",
  "add_ons": "Complementos",
  "there_no_services_generate": "Não há serviços para gerar",
  "boleto_generated": "Boleto gerado com sucesso",
  "issue_add_ons": "Emitir complementos",
  "make_sure_boleto_generated": "Certifique-se de que um boleto foi gerado para esta fatura",
  "invoice_settlement": "Liquidação de fatura",
  "reset_security_key": "Reiniciar chave de segurança",
  "assign_support": "Atribuir suporte",
  "acessing_user_account": "A aceder à conta do utilizador",
  "logging_in": "A iniciar sessão...",
  "back_to_list": "Voltar à lista",
  "menu": "Menu",
  "client_contracted_menu_registration": "O cliente contratou o registo do menu",
  "printer": "Impressora",
  "client_purchased_printer": "O cliente adquiriu uma impressora",
  "user_data": "Dados do utilizador",
  "salesperson": "Vendedor",
  "responsible_support": "Suporte Responsável",
  "name": "Nome",
  "no_name": "Sem nome",
  "no_email": "Sem e-mail",
  "invalid_email": "E-mail inválido",
  "ssn_ein": "NIF/NIPC",
  "no_ssn_ein": "Sem NIF/NIPC",
  "invalid": "Inválido",
  "no_whatsapp": "Sem número de telefone",
  "registration_date": "Data de registo",
  "no_date": "Sem data",
  "login_attempts": "Tentativas de início de sessão",
  "due_date": "Data de vencimento",
  "payment_method": "Método de pagamento",
  "card": "Cartão",
  "password": "Palavra-passe",
  "passwords": "Palavras-passe",
  "enter_new_password": "Introduza a nova palavra-passe",
  "security_key": "Chave de segurança",
  "reset": "Reiniciar",
  "access_panel": "Painel de Acesso",
  "not_have_permission_adm": "Não tem permissão para aceder a uma conta de Administrador",
  "clear_addresses": "Limpar Endereços",
  "clear": "Limpar",
  "delete_addresses_customer": "Deseja eliminar os endereços deste cliente?",
  "no": "Não",
  "yes": "Sim",
  "user_cancellation": "Cancelamento de Utilizador",
  "cancel_charges_user": "Cancelar cobranças para este utilizador?",
  "version_next": "Versão *Next*",
  "test": "teste",
  "activate": "Ativar",
  "automatic_printing_browser": "Impressão automática através do navegador",
  "observations": "Observações",
  "save": "Guardar",
  "title": "Título",
  "block_profile": "Bloquear Perfil",
  "value_whatsapp": "Valor no Número de telefone",
  "enable_shipping_mi": "Ativar envio por MI",
  "double_sending_whatsapp": "Envio duplo no Número de telefone",
  "enable_pix_online": "Ativar Pix Online",
  "enable_online_card": "Ativar Cartão Online",
  "old_pix": "Pix Antigo",
  "negotiation_fee": "Taxa de negociação",
  "days": "dias",
  "expires_in": "Expira em",
  "expired_since": "Expirado desde",
  "disconnect_account": "Desconectar Conta Asaas",
  "unlink": "Desvincular",
  "account_asaas": "Conta Asaas",
  "unlink_asaas_account": "Deseja desvincular a conta Asaas deste cliente?",
  "quantity": "Quantidade",
  "issue": "Emitir",
  "number_installments": "Número de Parcelas",
  "invoices": "Faturas",
  "transaction_id": "ID da Transação",
  "value": "Valor",
  "action": "Ação",
  "action_remove_user_the": "Esta ação irá remover o utilizador do",
  "notice": "Aviso",
  "settlement": "Liquidação",
  "warning_financial_password": "AVISO! Precisa de configurar uma Palavra-Passe Financeira.",
  "warning_v2": "Para isso, vá à opção Configurações no menu.",
  "passwords_or": "Palavras-passe ou",
  "click_here": "Clique aqui",
  "enter_financial_password": "Introduza a sua Palavra-Passe Financeira",
  "confirm": "Confirmar",
  "forgot_financial_password": "Esqueceu-se da Palavra-Passe Financeira?",
  "recover_password": "Recuperar palavra-passe",
  "check_inbox_spam": "verifique a sua 'caixa de entrada' ou a pasta 'spam'.",
  "message_subject": "a mensagem com o assunto",
  "password_recovery": "Recuperação de Palavra-Passe",
  "whastmenu": "Whatsmenu",
  "start_date": "Data de Início",
  "end_date": "Data de Fim",
  "search": "Pesquisar",
  "print": "Imprimir",
  "report": "Relatório",
  "product": "Produto",
  "products": "Produtos",
  "product_m": "produto",
  "qty_sold": "Qtde. Vendida",
  "total_amount": "Valor Total",
  "cash_summary": "RESUMO DE CAIXA",
  "qty": "Qtde.",
  "incomes": "Receitas",
  "table_closing": "Fecho de mesa",
  "table": "Mesa",
  "counter": "Balcão",
  "request": "Pedido",
  "cancelled": "Cancelado",
  "cancelled_up": "CANCELADO",
  "cancelled_o": "Cancelado",
  "outflows": "Despesas",
  "n_orders": "Nº de Pedidos",
  "language": "Idioma",
  "select_language": "Selecione o Idioma",
  "client": "Cliente",
  "currency": "Moeda",
  "brazil": "Brasil (R$)",
  "united_states": "Estados Unidos ($)",
  "portugal": "Portugal (€)",
  "united_kingdom": "Inglaterra (£)",
  "country": "País",
  "select_currency": "Selecionar Moeda",
  "portuguese_brazil": "Português (Brasil)",
  "english_us": "Inglês (EUA)",
  "english_ar": "Inglês (Dubai)",
  "english_uk": "Inglês (Grã-Bretanha)",
  "portuguese_portugal": "Português (Portugal)",
  "average_ticket": "Ticket Médio",
  "total_spent": "Total Gasto",
  "total_orders": "Total de Pedidos",
  "total_amount_spent": "Valor Total Gasto",
  "customer_report": "Relatório de Cliente",
  "number_of_orders": "Nº de Pedidos",
  "order_history": "Histórico de Pedidos",
  "change_for": "Troco Para",
  "change": "Troco",
  "payment": "Pagamento",
  "all_customers": "Todos os Clientes",
  "filter": "Filtrar",
  "order_volume": "Volume de Pedidos",
  "amount_consumed": "Valor Consumido",
  "no_results_found": "Nenhum resultado encontrado",
  "no_customer_found": "Nenhum cliente encontrado com esse nome",
  "phone_number": "Número de Telefone",
  "customer_found": "Clientes Encontrados",
  "opening_time_must_closing": "A hora de abertura deve ser posterior à hora de fecho!",
  "time_will_not_take": "Este horário não terá efeito no",
  "following": "Seguinte",
  "day": "Dia",
  "changing_existing_time": "considere alterar um horário existente no",
  "mentioned": "Mencionado",
  "please_select_day": "Por favor, selecione um dia!",
  "please_set_valid_time": "Por favor, defina um horário válido!",
  "operating_hours": "Horário de Funcionamento",
  "the_change_were": "As alterações não foram feitas porque há horários inválidos. Por favor, corrija os campos destacados e tente novamente.",
  "product_availability": "Disponibilidade do Produto",
  "setting_operating_hours": "Configuração do Horário de Funcionamento",
  "choose_days_times": "ESCOLHA OS DIAS E HORÁRIOS",
  "select_all": "Selecionar Todos",
  "from": "De",
  "until": "Até",
  "add": "Adicionar",
  "open": "Aberto",
  "close": "Fechado",
  "actions": "Ações",
  "invalid_time": "Horário inválido",
  "if_work_overnight": "Se também trabalha durante a noite, ou seja, após a meia-noite, registe as horas assim",
  "pause": "Pausa",
  "unpause": "Retomar",
  "delete": "Eliminar",
  "type": "Tipo",
  "priority": "Prioridade",
  "email_server": "Servidor de Email",
  "content": "Conteúdo",
  "destination": "Destino",
  "address": "Endereço",
  "discount": "Desconto",
  "surchage": "Sobretaxa",
  "fixed": "Fixo",
  "percentage": "Percentagem",
  "type_of_fee": "Tipo de taxa",
  "type_of_value": "Tipo de valor",
  "fee_amount": "Montante da taxa",
  "copy": "COPIAR",
  "successfully_duplicated": "duplicado com sucesso",
  "not_possible_duplicate": "Não foi possível duplicar a categoria",
  "unable_duplicate_product": "Não foi possível duplicar o produto.",
  "category": "Categoria",
  "successfully": "Com sucesso",
  "the_category": "a categoria",
  "could_not": "Não foi possível",
  "paused": "Pausado",
  "unpaused": "Retomado",
  "the_product": "o produto",
  "sizes": "Tamanhos",
  "size": "Tamanho",
  "edges_doughs": "Bordas e Massas",
  "flavors": "Sabores",
  "menu_information": "Informações do Menu",
  "cat_standard": "cat. Padrão",
  "qty_product": "Qtde. Produtos",
  "qty_add_ons": "Qtde. Adicionais",
  "qty_size": "Qtde. Tamanhos",
  "qty_edges_doughs": "Qtde. Bordas/Massas",
  "qty_flavors": "Qtde. Sabores",
  "you_edited": "Você editou",
  "items": "itens",
  "cancel_edit": "Deseja cancelar a edição",
  "there_are_no": "Não há",
  "to_update": "para atualizar",
  "disconnect_account_asaas": "Desconectar Conta Asaas",
  "package": "Pacotes",
  "appointments": "Agendamentos",
  "appointment": "Agendamento",
  "share_facebook": "Partilhar no Facebook",
  "copy_clipboard": "Copiar para a área de transferência",
  "copied": "Copiado",
  "link_sucess_copied": "Link copiado com sucesso.",
  "unlink_link_copied": "Não foi possível copiar o link, o seu navegador não suporta esta funcionalidade.",
  "message_menu_adjust_prices": "O menu é a sua vitrine de produtos no WhatsMenu. Aqui, pode criar categorias, adicionar itens, extras e opções, definir disponibilidade e ajustar preços.",
  "add_category": "Adicionar Categoria",
  "reorder_menu": "Reordenar Menu",
  "search_g": "Pesquisar",
  "search_place": "Pesquisar...",
  "complements": "Complementos",
  "complement": "Complemento",
  "pizza_flavors": "Sabores de Pizza",
  "duplicate": "Duplicar",
  "edit": "Editar",
  "stock": "Estoque",
  "low": "Baixo",
  "out_stock": "Sem estoque",
  "edit_cover": "Editar Capa",
  "add_size": "Adicionar Tamanho",
  "add_item": "Adicionar Item",
  "add_complements": "Adicionar Complemento",
  "edit_flavors": "Editar Sabores",
  "cancel_edits": "Cancelar Edição",
  "prices": "Preços",
  "price": "Preço",
  "photo": "Foto",
  "discard_changes": "Descartar alterações",
  "enter_valid_value": "Por favor, insira um valor válido!",
  "in_stock": "EM ESTOQUE",
  "add_flavors_firt_pizza": "Para adicionar sabores, primeiro crie um tamanho de pizza.",
  "add_flavors": "Adicionar Sabores",
  "updated_now": "Atualizado agora",
  "flavors_will_be": "os sabores serão",
  "flavor_will_be": "o sabor será",
  "please_wait": "Por favor, aguarde...",
  "coin_t": "Moeda",
  "please_wait_n": "Por favor, aguarde",
  "edit_products": "Editar Produtos",
  "products_will_be": "os produtos serão",
  "product_will_be": "o produto será",
  "no_results_your_search": "Nenhum resultado para a sua pesquisa",
  "no_category_registred": "Nenhuma categoria registrada",
  "add_items_bulk": "Adicionar itens em massa",
  "you_changing_promotional_price": "Você está alterando o preço promocional do produto.",
  "system": "Sistema",
  "cash_detail": "Detalhe de Caixa",
  "operator": "Operador",
  "image_preview": "Pré-visualização da Imagem",
  "width": "Largura",
  "height": "Altura",
  "complete": "Completo",
  "review_entered_data": "Por favor, reveja os dados inseridos.",
  "confirm_financial_password": "Confirmar Senha Financeira",
  "financial_password": "Senha Financeira",
  "how_to_make": "Como fazer",
  "updated": "atualizado",
  "updated_o": "atualizado",
  "created": "criado",
  "successfully_deleted": "foi eliminado com sucesso",
  "unable_delete_category": "Não foi possível eliminar a categoria",
  "standard": "Padrão",
  "delete_category": "Eliminar a categoria",
  "review_data": "Reveja os dados",
  "details": "Detalhes",
  "availability": "Disponibilidade",
  "select_category_model": "Selecionar o modelo de categoria",
  "acai_ice_creams": "Açaí e Gelados",
  "burgers": "hambúrgueres",
  "meal_boxes": "Caixas de refeição",
  "meal_boxes_size_options": "Caixas de refeição com opções de tamanho",
  "build_meal_box": "Monte a sua própria caixa de refeição",
  "butcher_shop": "Talho",
  "produce_section": "Secção de Produtos Frescos",
  "drinks": "Bebidas",
  "category_name": "Nome da Categoria",
  "invalid_category_name": "Nome de categoria inválido",
  "characters": "caracteres",
  "minimum_stock": "Estoque mínimo",
  "add_availability_hours": "Adicionar horários de disponibilidade",
  "use_addons_another_item": "Usar complementos de outro item",
  "creating_addons": "Criando complementos",
  "shared_addons": "Complementos compartilhados",
  "attention": "Atenção",
  "attention_up": "ATENÇÃO",
  "all_changes_addon_affect_other_product": "Todas as alterações feitas a este complemento afetarão outros produtos que o utilizam.",
  "link": "Link",
  "attention_addon_linked": "Atenção este complemento está vinculado a",
  "attention_addon_linked_v2": "qualquer alteração feita aqui afetará os produtos vinculados, exceto para exclusões.",
  "other": "outro",
  "view_linked_products": "Ver produtos vinculados",
  "invalid_name": "Nome inválido",
  "min_greater_max": "Mínimo maior que o máximo",
  "max_less_min": "Máximo menor que o mínimo",
  "mandatory_addon": "Complemento obrigatório",
  "option": "Opção",
  "please_enter_valid_value": "Por favor, insira um valor válido",
  "always_available": "Sempre disponível",
  "enable_stock": "Habilitar estoque",
  "created_o": "criado",
  "create": "Criar",
  "was_deleted_successfully": "foi eliminado com sucesso",
  "could_not_delete_flavor": "Não foi possível eliminar o sabor",
  "you_really_want_delete": "Tem a certeza de que deseja eliminar",
  "error": "erro",
  "the_flavor": "o sabor",
  "flavor": "Sabor",
  "image_up_8": "Imagem de até 8MB",
  "add_image": "Adicionar Imagem",
  "recomended_resolution_600": "resolução recomendada de 600x450",
  "please_valid_name": "Por favor, insira um nome válido.",
  "message_pause_sales": "Para pausar as vendas deste item, clique no botão ao lado. Se o botão estiver ativado, o item não aparecerá na sua lista de pratos.",
  "pause_sales": "Pausar Vendas",
  "resume_sales": "Retomar Vendas",
  "created_successfully": "criado com sucesso.",
  "updated_successfully": "atualizado com sucesso.",
  "was_not_created": "não foi criado.",
  "was_not_updated": "não foi atualizado.",
  "deleted_successfully": "eliminado com sucesso.",
  "could_not_delete": "Não foi possível eliminar.",
  "could_not_pause": "Não foi possível pausar",
  "resumed_successfully": "retomado com sucesso.",
  "paused_successfully": "pausado com sucesso",
  "to_pause_sale_message": "Para pausar as vendas deste item, clique no botão ao lado. Se o botão estiver ativado, o item não aparecerá na sua lista de pratos.",
  "size_name_already_exists": "Já existe um tamanho com este nome.",
  "the_size": "o tamanho",
  "update": "atualizar",
  "message_delete_remove_another": "Para eliminar, é necessário remover os sabores ou adicionar outro tamanho de pizza",
  "message_really_delete_size": "Tem a certeza de que deseja eliminar o tamanho",
  "message_could_not_delete": "Não foi possível eliminar o tamanho",
  "edit_size": "Editar Tamanho",
  "cover": "Capa",
  "this_size_accepts": "Este tamanho aceita",
  "add_cover": "Adicionar Capa",
  "message_cannot_invalid_times": "As alterações não podem ser feitas porque há horários inválidos. Por favor, corrija os campos destacados e tente novamente",
  "review_not_allowed": "Revise os seus complementos; nomes vazios não são permitidos",
  "could_not_delete_product": "Não foi possível eliminar o produto",
  "delete_the_product": "Eliminar o produto",
  "promotion": "Promoção",
  "activate_promotion": "Ativar promoção",
  "button_enable_disable_promotion": "Use o botão ao lado para ativar ou desativar a promoção.",
  "original_price": "Preço original",
  "promotional_price": "Preço promocional",
  "available_for": "Disponível para",
  "message_products_not_created": "Os produtos não foram criados porque há horários inválidos. Por favor, corrija os campos destacados e tente novamente",
  "products_created_successfully": "Todos os produtos foram criados com sucesso.",
  "products_not_created": "Os produtos não foram criados.",
  "create_products_bulk": "Criar Produtos em Massa",
  "shortcuts": "Atalhos",
  "right_arrow": "Seta direita",
  "left_arrow": "Seta esquerda",
  "next_step": "Próximo passo",
  "back_one_step": "Voltar um passo",
  "create_products": "Criar produtos",
  "add_product": "Adicionar produto",
  "duplicate_product": "Duplicar produto",
  "product_name": "Nome do produto",
  "table_price": "Preço de tabela",
  "product_description": "Descrição do produto",
  "duplicate_r": "Duplicar",
  "no_product_added": "Nenhum produto adicionado",
  "creating_products": "Criando produtos...",
  "reordered_successfully": "Reordenado com sucesso",
  "could_not_reorder_items": "Não foi possível reordenar os itens",
  "message_press_and_hold": "Pressione e segure por 1 segundo, depois arraste os itens para a posição desejada. Esta sequência também será refletida na sua loja.",
  "reordegring_wait": "Reordenando, por favor, aguarde...",
  "you_any_categories_yet": "Ainda não registou nenhuma categoria.",
  "please_review_data": "Por favor, reveja os dados.",
  "review": "Rever",
  "fee_shipping": "Envio Grátis",
  "fee_shipping_up": "ENVIO GRÁTIS",
  "fee": "Taxa",
  "disc": "Desc.",
  "free": "Grátis",
  "be_determined": "A ser determinado",
  "disabled": "Desativado",
  "order_slip": "Recibo do Pedido",
  "order_slips": "Recibos dos Pedidos",
  "ssn": "NIF",
  "duration": "Duração",
  "delivery_date": "Data de Entrega",
  "paid_up": "PAGO",
  "coupon_used": "Cupão utilizado",
  "delivery": "Entrega",
  "coupon": "Cupão",
  "payment_in": "Pagamento em",
  "total_paid": "Total Pago",
  "checkout": "Finalizar compra",
  "paid_online_up": "PAGO ONLINE",
  "delivery_person": "Entregador",
  "s_n": "S/N",
  "technology": "Tecnologia",
  "missing": "Faltando",
  "waiter": "Garçom",
  "paid": "Pago",
  "delivery_fee": "Taxa de Entrega",
  "printing_error": "Erro de impressão",
  "could_not_send_printing": "Não foi possível enviar a cópia para impressão.",
  "order_confirmation": "Confirmação do pedido",
  "could_not_print_order": "Não foi possível confirmar a impressão do pedido",
  "change_date": "Alterar data",
  "pending_prints": "Impressões pendentes",
  "message_pending_print": "pedidos de impressão pendentes. Deseja imprimir estes pedidos?",
  "there_are": "Existem",
  "print_all": "Imprimir todos",
  "could_not_play_sound": "Não foi possível reproduzir o som.",
  "printing_up": "IMPRIMINDO",
  "cancel_order": "Cancelar pedido",
  "reinstate_order": "Restaurar pedido",
  "no_orders_print_up": "NÃO HÁ PEDIDOS PARA IMPRIMIR",
  "order_preview": "Pré-visualização do pedido",
  "teste_print_order": "Impressão de teste do pedido",
  "report_request": "Pedido de relatório",
  "received": "Recebido",
  "preparation": "Preparação",
  "delivering": "Entrega",
  "served": "Servido",
  "ready_for_pickup": "Pronto para recolha",
  "speak_customer": "Falar com o cliente",
  "select": "Selecionar",
  "add_address": "Adicionar endereço",
  "edit_address": "Editar endereço",
  "filling_establishment_address": "Preenchendo o endereço do estabelecimento",
  "zip_code": "Código Postal",
  "invalid_zip_code": "Código postal inválido",
  "number": "Número",
  "neighborhood": "Localidade",
  "city": "Cidade",
  "enter_registed_email_recover_password": "Insira o seu e-mail registrado para recuperar a sua senha",
  "please_enter_valid_email": "Por favor, insira um e-mail válido",
  "email_sent_successfully": "E-mail enviado com sucesso.",
  "send": "Enviar",
  "delivery_package": "ENTREGA/PACOTES",
  "change_order_date": "Alterar data do pedido",
  "selected_date": "Data selecionada",
  "current_order_date": "Data atual do pedido",
  "distribution": "Distribuição",
  "order_coder": "Código do pedido",
  "no_order_selected": "Nenhum pedido selecionado",
  "go_back": "Voltar",
  "pickup": "Recolha",
  "deliver": "Entregar",
  "no_items_this_date": "Nenhum item para esta data",
  "all": "Todos",
  "pending": "Pendente",
  "marked_received": "Marcado como recebido",
  "local_delivery": "Entrega local",
  "summary_of": "Resumo de",
  "your": "seu",
  "your_a": "seu",
  "filter_use": "Filtro em uso",
  "choose_date_summary": "Escolha uma data para o resumo",
  "printing": "Impressão",
  "selected_orders": "Pedidos selecionados",
  "regarding": "Relativo a",
  "calendar_view": "Vista de calendário",
  "loading_orders": "A carregar pedidos...",
  "order_slip_fees": "Taxas de recibo de pedido",
  "fees": "Taxas",
  "charge": "Cobrança",
  "next_confirm": "Próximo/Confirmar",
  "back_cancel": "Voltar/Cancelar",
  "proceed": "Prosseguir",
  "delivery_report": "Relatório de entrega",
  "qty_of_deliveries": "Qtd. de entregas",
  "total_delivery_fees": "Total das taxas de entrega",
  "delivery_report_r": "Relatório de entrega",
  "code_order": "Código do pedido",
  "date": "Data",
  "discard": "Descartar",
  "pending_changes": "Alterações pendentes",
  "you_whant_save_them": "Há alterações não salvas. Deseja salvá-las?",
  "new": "Novo",
  "my_profile": "Meu perfil",
  "delivery_drivers": "Motoristas de entrega",
  "tables": "Mesas",
  "no_active_waiter": "Nenhum garçom ativo",
  "message_register_waitr_enable": "Para registrar um garçom ou ativar um existente, vá para Configurações > Mesa > na aba Garçons, ou clique em",
  "register_waiter": "Registrar Garçom",
  "coupons": "Cupões",
  "social_media": "Redes sociais",
  "reports": "Relatórios",
  "financial_report": "Relatório financeiro",
  "daily_orders": "Pedidos diários",
  "monthly_orders": "Pedidos mensais",
  "cash_register_closing": "Fecho da caixa",
  "best_sellers": "Mais vendidos",
  "customer_report_side": "Relatório de clientes",
  "general": "Geral",
  "cash_registers": "Caixas",
  "domains": "Domínios",
  "payment_methods": "Métodos de pagamento",
  "administrator": "Administrador",
  "support_bonus": "Bónus de suporte",
  "salesperson_report": "Relatório de vendedores",
  "annual_report": "Relatório anual",
  "updates": "Atualizações",
  "exit": "Sair",
  "support_hours": "De seg a sáb, das",
  "settings": "Configurações",
  "phone": "Telefone",
  "press": "Pressione",
  "order_already_printed": "Pedido já impresso",
  "order_not_printed": "Pedido não impresso",
  "wait_order_printed": "Por favor, aguarde a impressão do pedido.",
  "displaying": "A mostrar",
  "of": "de",
  "filter_o": "Filtro",
  "caption": "Legenda",
  "order_list": "Lista de Pedidos",
  "message_download_whatsmenu_desktop": "Descarregue o NOVO WhatsMenu Desktop agora",
  "message_less_work": "Menos trabalho e mais vendas",
  "message_virtual_assistant": "Assistente virtual para número de telefone",
  "message_loyalty_program": "Programa de fidelidade",
  "message_first_purchase": "Cupão para a primeira compra",
  "message_automated_sales_bot": "Bot de vendas automatizado",
  "message_status_updates": "Atualizações de status sem abrir abas",
  "message_download_app": "Descarregar Aplicação",
  "donwload_the_whatsmenu": "Descarregar o WhatsMenu Desktop",
  "message_download_whatsmenu_printers": "Descarregar os novos WhatsMenu Printers agora",
  "message_automatic_printing_mobile": "Impressão automática no móvel",
  "message_printing_multiple_printers": "Impressão em várias impressoras",
  "message_copy_printing": "Impressão em cópias",
  "message_download_gplay": "Descarregar via Google Play",
  "message_downlaod_whatsmenu_printers": "Descarregar WhatsMenu Printers",
  "message_audio_disabled": "Áudio desativado. Por favor, interaja com a tela para ativá-lo.",
  "message_monthly_invoice_available": "Fatura mensal disponível para pagamento! Clique aqui.",
  "message_menu_locked": "O seu menu pode estar bloqueado porque a sua assinatura está em atraso! Clique aqui.",
  "overview_pos_pizza_addons": "Visão geral do POS e Adicionais de Pizza WhatsMenu",
  "how_to_register_customer_pos": "Como registar um cliente no POS",
  "guide_placing_order_pos": "Guia passo a passo para realizar um pedido via POS",
  "how_to_update_customer_information_pos": "Como atualizar informações do cliente via POS",
  "guide_opening_cash_register": "Guia passo a passo para abrir a caixa registadora",
  "guide_closin_cash_register": "Guia passo a passo para fechar a caixa registadora",
  "cash_register_closing_reports": "Relatórios de fecho da caixa registadora",
  "how_place_order_counter_pos": "Como fazer um pedido para levar no balcão via POS",
  "reordering_customers_orders": "Repetir pedidos anteriores de clientes com poucos cliques",
  "place_order_pizza_pos": "Como fazer um pedido de pizza com 2 ou mais coberturas, bases e adicionais via POS",
  "how_create_tables": "Como criar mesas",
  "guide_registering_pizza_crusts": "Guia passo a passo para registar pizzas com bases e coberturas",
  "pos": "POS",
  "internet_connection_lost": "A sua ligação à internet foi perdida. Por favor, verifique a sua ligação.",
  "internnet_connection_reestablished": "A sua ligação à internet foi restabelecida.",
  "register_your_customers_quickly_easily": "Registe os seus clientes e faça pedidos de forma mais rápida e fácil.",
  "repeat_your_customer_clicks": "Repita o último pedido do seu cliente com apenas 2 cliques.",
  "cash_register_simply": "Abertura e fecho da caixa registadora com relatórios fantásticos.",
  "gain_extra_speed": "Ganhe velocidade extra no atendimento às mesas.",
  "support_more_information": "O seu plano completo expirou e não detectámos o pagamento. Por favor, contacte o suporte para mais informações.",
  "dear_customer": "Caro Cliente,",
  "view_menu": "Ver Menu",
  "have": "tem",
  "not_have": "não tem",
  "new_orders": "novos pedidos.",
  "go_to_orders": "Ir para pedidos",
  "general_settings": "Configurações Gerais",
  "need_help": "Precisa de Ajuda?",
  "you": "Você",
  "copied_clipboard": "Copiado para a área de transferência",
  "copy_n": "Copiar",
  "browser_not_have_copy": "O seu navegador não tem a opção de copiar.",
  "cashback_status": "Status de Cashback",
  "cashback_configuration": "Configuração de Cashback",
  "loyalty": "Fidelidade",
  "percentage_of": "Percentagem",
  "percentage_up": "PERCENTAGEM",
  "validity": "Validade",
  "days_n": "Dias",
  "in_days": "Em dias",
  "how_to_configure_cashback": "Como configurar o Cashback no WhatsMenu",
  "activate_cashback": "Ativar Cashback",
  "promotion_link": "Link de Promoção",
  "profile_information": "Informações do Perfil",
  "store_name": "Nome da Loja",
  "enter_store_name": "Insira o Nome da Loja",
  "enter_slug": "Insira o seu slug",
  "contact_phone_number": "Número de Telefone para Contato",
  "enter_contact_phone": "Insira o número de telefone para contato",
  "message_phone_number_will": "Este número de telefone estará disponível apenas para clientes com pedidos em andamento.",
  "enter_store_description": "Insira a descrição da sua loja",
  "minimum_delivery_order": "Pedido Mínimo de Entrega",
  "minimum_order_excluding_delivery": "Valor mínimo do pedido, excluindo a taxa de entrega.",
  "minimum_order_pickup": "Pedido Mínimo para Recolha",
  "minimum_order_pickup_delivery": "Valor mínimo do pedido para recolha, excluindo a taxa de entrega.",
  "preview_your_store": "Pré-visualização da sua loja",
  "preview": "Pré-visualizar",
  "open_n": "Abrir",
  "calculate": "Calcular",
  "store_color": "Cor da Loja",
  "wait_time": "Tempo de Espera",
  "establishment": "Estabelecimento",
  "store_layout": "Layout da Loja",
  "shipping": "Envio",
  "congratulations": "Parabéns",
  "message_few_steps_organized": "Em apenas alguns passos, terá um serviço rápido e organizado com o seu",
  "digital_menu": "Menu Digital.",
  "basic_details_establishment": "Agora preencha os detalhes básicos do seu estabelecimento.",
  "filling_profile": "Preenchendo perfil",
  "restaurant_address": "Endereço do Restaurante",
  "message_plan_not_include_delivery": "O seu plano atual não inclui a funcionalidade de Entrega ou Pedidos/Agendamentos. Por favor, contacte o suporte para mais detalhes.",
  "closing_scheduled_time": "Fecho fora do horário agendado",
  "reopening_date": "Data de Reabertura",
  "time_zone": "Fuso Horário",
  "select_time_zone": "Selecione o seu fuso horário",
  "now": "Agora",
  "add_business_hours": "Adicionar Horário Comercial",
  "store": "Loja",
  "closed": "Fechado",
  "open_a": "aberto",
  "monday": "Segunda-feira",
  "tuesday": "Terça-feira",
  "wednesday": "Quarta-feira",
  "thursday": "Quinta-feira",
  "friday": "Sexta-feira",
  "saturday": "Sábado",
  "sunday": "Domingo",
  "delivery_fees": "Taxas de Entrega",
  "shipping_neighborhood": "Envio por localidade",
  "shipping_by_mi": "Envio por MI",
  "mile": "Milha",
  "delivery_type_change_to": "Tipo de entrega alterado para",
  "loading": "A carregar...",
  "delivery_type": "Tipo de Entrega",
  "by_mile": "Por Milha",
  "reset_fees": "Redefinir Taxas",
  "message_fee_deleted": "Se alterar o tipo de taxa, todas as taxas atuais serão eliminadas",
  "message_really_change_fee": "Deseja realmente alterar o tipo de taxa?",
  "by_neighborhood": "Por Localidade",
  "neighborhood_name": "Nome da Localidade",
  "neighborhood_cannot_duplicated": "A localidade não pode ser duplicado",
  "to_be_confirmed": "A ser confirmado",
  "neighborhood_added": "Localidade adicionada",
  "city_and_neighborhood_added": "Cidade e localidade adicionadas",
  "neighborhood_deleted": "Localidade eliminada",
  "city_deleted": "Cidade eliminada",
  "time_minutes": "Tempo em Minutos",
  "changes_made_successfully": "Alterações efetuadas com sucesso",
  "unexpected_try_again": "Ocorreu algo inesperado. Por favor, tente novamente.",
  "success": "Sucesso",
  "online_payment_automatic": "Pagamento Online - Automático",
  "message_avoid_fraud_errors": "Evite fraudes, erros e atrasos no serviço ao não ter de verificar os recibos no aplicativo bancário.",
  "asaas_terms_use": "Termos de Utilização do Asaas Bank",
  "acess_email": "Aceda ao email",
  "message_open_asaas_email": "Abra o email do Asaas e clique no link para criar a sua senha bancária.",
  "message_sent_sms_token": "Enviámos o SMS com o token para",
  "account_creation_details": "Detalhes da Criação da Conta",
  "branch": "Agência",
  "acess_asaas_account": "Aceda à sua conta Asaas",
  "termis_of_use": "Termos de Utilização",
  "online_payment": "Pagamento Online",
  "payment_on_delivery_card": "Pagamento na Entrega - Leitor de Cartões",
  "payment_on_delivery": "Pagamento na Entrega",
  "automate_your_payments": "Automatize o recebimento dos seus pagamentos",
  "activate_this": "Ativar Isto",
  "notes": "Notas",
  "up_two_business_days": "Até 2 dias úteis",
  "acess_detailed_statements_asaas": "Nota: Aceda ao seu aplicativo Asaas Bank para extratos detalhados.",
  "enable_surcharge_discount": "Ativar Sobretaxa/Desconto",
  "disabled_o": "Desativado",
  "activated": "Ativado",
  "card_network": "Rede de Cartões",
  "add_card_network": "Adicionar Rede de Cartões",
  "key_type": "Tipo de Chave",
  "key": "Chave",
  "invalid_pix": "Pix Inválido",
  "money": "Dinheiro",
  "pix": "Pix",
  "picpay": "PicPay",
  "credit_card": "Cartão de Crédito",
  "credit": "Crédito",
  "debit": "Débito",
  "mbway": "MBWay",
  "spin": "Spin",
  "register_password": "Senha de Registo",
  "meal_voucher": "Vale Refeição",
  "food_voucher": "Vale Alimentação",
  "month": "Mês",
  "year": "Ano",
  "january": "Janeiro",
  "february": "Fevereiro",
  "march": "Março",
  "april": "Abril",
  "may": "Maio",
  "june": "Junho",
  "july": "Julho",
  "august": "Agosto",
  "september": "Setembro",
  "october": "Outubro",
  "november": "Novembro",
  "december": "Dezembro",
  "search_for": "Procurar",
  "summary": "Resumo",
  "number_orders": "Número de Pedidos",
  "no_fees": "Sem Taxas",
  "deliverys": "Entregas",
  "number_deliveries": "Número de Entregas",
  "total_sales_and_coupons": "Vendas totais para o período - Total de cupões",
  "total_canceled": "Total Cancelado",
  "number_canceled_orders": "Número de Pedidos Cancelados",
  "total_fees": "Taxas Totais",
  "no_fees_period": "Sem taxas para este período",
  "fixed_amount": "VALOR FIXO",
  "fixed_amount_n": "Valor Fixo",
  "payment_methods_n": "Métodos de Pagamento",
  "waitstaff": "Empregados de Mesa",
  "creation_date": "Data de Criação",
  "daily_report": "Relatório Diário",
  "monthly_report": "Relatório Mensal",
  "delivery_registration": "Registo de Pessoal de Entrega",
  "error_deleting_delivery_person": "Erro ao eliminar o pessoal de entrega",
  "error_pausing_activating": "Erro ao pausar/ativar o pessoal de entrega",
  "error_editing_delivery_person": "Erro ao editar o pessoal de entrega",
  "delivery_person_name": "Nome do Entregador",
  "enter_delivery_person": "Insira o nome do entregador",
  "enter_whatsapp_number": "Insira o número de telefone",
  "registered_delivery": "Pessoal de Entrega Registado",
  "fill_all_fields": "Por favor, preencha todos os campos.",
  "coupon_management": "Gestão de Cupões",
  "coupon_code": "Código do Cupão",
  "coupon_already_exists": "Este cupão já existe",
  "offer_type": "Tipo de Oferta",
  "coupon_type": "Tipo de Cupão",
  "first_purchase": "Primeira Compra",
  "discount_amount": "Montante de Desconto",
  "minimum_amount": "Montante Mínimo",
  "code": "Código",
  "unable_create_the_coupon": "Não foi possível criar o cupão",
  "coupon_not_created": "Cupão não criado.",
  "status_coupon": "Estado do Cupão",
  "actives": "ativos",
  "for_orders": "para pedidos",
  "inactive": "inativo",
  "delete_coupon": "Eliminar Cupão",
  "business_offer_deliveries": "O seu negócio oferece entregas?",
  "customer_pick_up_order_onsite": "O cliente pode levantar o pedido no local",
  "cash_register": "Caixa",
  "active_cash_management": "Ativar gestão de caixa",
  "message_currently_open_cash": "Atualmente tem caixas abertas. Por favor, feche-as antes de alterar a gestão da caixa",
  "before_register_management": "antes de alterar a gestão da caixa",
  "customers": "Clientes",
  "display_date_birth": "Exibir Data de Nascimento",
  "require_date_birth": "Exigir Data de Nascimento",
  "default_texts": "Textos Padrão",
  "changing_default_text_observations": "Alterar texto padrão para observações",
  "product_note": "Nota do Produto",
  "example_no_mayonnaise": "Ex.: Sem maionese, sem salada, etc.",
  "pizza_note": "Nota da Pizza",
  "leave_any_note_product_here": "Deixe qualquer nota sobre o produto aqui.",
  "message_client": "Olá [NOME], como está?",
  "status_received": "Estado Recebido",
  "you_order_production": "[NOME] O seu pedido está em produção!",
  "status_delivering": "Estado Entrega",
  "yay_order_already_way": "Yay [NOME], o seu pedido já está a caminho!",
  "status_pickup": "Estado Levantamento",
  "order_ready_pickup": "Yay, [NOME], o seu pedido está pronto para levantamento!",
  "order_status_pos": "Estado do Pedido no POS",
  "order_successfully_placed": "[NOME], o seu pedido foi realizado com sucesso. Acompanhe o estado do seu pedido abaixo!",
  "greeting_message": "Mensagem de Cumprimento",
  "message_hello": "Olá, [NOME]!",
  "welcome_to": "Bem-vindo a",
  "check_menu_order": "Veja o nosso menu para fazer o seu pedido",
  "exclusive_offers": "Ofertas exclusivas para pedidos através do link",
  "team": "Equipe",
  "use_a_printer": "Utilizar uma impressora",
  "group_order_printing": "Agrupar itens do pedido para impressão",
  "print_test": "Impressão de Teste",
  "test_print": "Impressão de Teste",
  "printer_driver": "Driver de Impressora",
  "others": "Outros",
  "printing_app": "Aplicativo de Impressão",
  "print_width": "Largura da Impressão",
  "letters": "Letras",
  "small": "Pequeno",
  "large": "Grande",
  "copies": "Cópias",
  "necessary_whatsmenu_android_installed": "É necessário ter o aplicativo Whatsmenu Printer 2.0 instalado (apenas Android).",
  "pizza_options": "Opções de Pizza",
  "assign_price_most_expensive": "Atribuir o preço do mais caro",
  "different_crusts_each_flavor": "Permitir diferentes crostas para cada sabor",
  "allow_each_flavor": "Permitir coberturas para cada sabor",
  "stock_options": "Opções de Stock",
  "display_out_of_stock_product_addons": "Exibir estado 'esgotado' para produtos e complementos pausados?",
  "hide_ssn_receipt": "Ocultar opção SSN no recibo",
  "description_top_product_page": "Exibir foto e descrição do produto no topo da página do produto",
  "activate_catalog_mode_delivery": "Ativar modo catálogo na delivery - Não será possível finalizar a compra, apenas visualizar os produtos.",
  "activate_catalog_the_table": "Ativar modo catálogo na mesa - Não será possível finalizar a compra, apenas visualizar os produtos.",
  "automations": "Automatizações",
  "send_status_page_pos_customer": "Deseja enviar a página de estado no POS para o cliente?",
  "only_indentification": "Apenas a identificação",
  "label": "Rótulo",
  "saving": "A guardar...",
  "are_no_pending_changes": "Não há alterações pendentes",
  "no_changes": "Sem alterações",
  "saved": "Guardado",
  "saved_successfully": "Guardado com sucesso.",
  "order_date": "Data do Pedido",
  "tomorrow": "Amanhã",
  "today": "Hoje",
  "deliver_pickup": "Entrega/Levantamento",
  "delivery_e": "Entrega",
  "closed_o": "Fechado",
  "receive_orders_from": "Para receber pedidos de",
  "enable_options_the_settings_for": "ativar as opções nas definições para",
  "printed": "Imprimido",
  "are_no_orders_moment": "Não há pedidos no momento",
  "hours": "Horas",
  "review_delivery_hours": "Por favor, reveja as horas de entrega.",
  "changes_saved": "Alterações guardadas com sucesso.",
  "not_saved": "Não Guardado",
  "unable_save_check_connection": "Não foi possível guardar alterações, por favor verifique a sua conexão.",
  "no_changes_to_save": "Não há alterações para guardar.",
  "delivery_type_activated": "Pelo menos um tipo de entrega deve estar ativado",
  "pickups": "Levantamentos",
  "deliveries_pickups": "Entregas/Levantamentos",
  "delivery_types_must_one_activated": "Os tipos de entrega devem ter pelo menos um ativado",
  "activate_orders": "Ativar Pedidos",
  "delivery_scheduling": "Agendamento de Entregas",
  "order_settings": "Configurações do Pedido",
  "pickup_onsite": "Levantamento no Local",
  "allow_onesite_pickups": "Permitir Levantamentos no Local",
  "allow_delivery_services": "Permitir Serviços de Entrega",
  "make_available": "Disponibilizar",
  "starting_from": "começando a partir de",
  "allow": "Permitir",
  "for_day": "para o dia",
  "within": "dentro de",
  "group": "Grupo",
  "every": "cada",
  "minutes": "Minutos",
  "max_of": "Máx. de",
  "per_day_from": "por dia de 1 a 1000",
  "per_hour": "por hora",
  "display_menu_as": "Exibir no menu como",
  "minimum_for_delivery": "Mínimo para entrega",
  "minimum_for_pickup": "Mínimo para levantamento",
  "register_order_cash_register": "Registar Pedidos na Caixa",
  "on_day_ordered": "No dia em que foi solicitado",
  "on_delivery_day": "No dia da entrega",
  "availability_of": "Disponibilidade de",
  "dates_not_be_available": "Datas em que não estará disponível",
  "select_special_dates": "Selecione as datas especiais em que não estará disponível",
  "select_a_date": "Selecione uma data",
  "defined_dates": "Datas Definidas",
  "remove": "Remover",
  "values": "Valores",
  "minimum_order_for_pickup": "Pedido Mínimo para Levantamento",
  "table_name_already_exists": "Já existe uma mesa com este nome",
  "create_table": "Criar Mesa",
  "enter_valid_name": "Por favor, insira um nome válido",
  "new_table": "Nova Mesa",
  "creating_table": "Criando mesa",
  "using_table_system": "Utilizando o sistema de mesas",
  "time_zone_successfully_changed": "Fuso horário alterado com sucesso",
  "table_name": "Nome da Mesa",
  "cancel": "Cancelar",
  "available": "Disponível",
  "occupied": "Ocupada",
  "paused_a": "Pausada",
  "selected": "Selecionada",
  "you_haven_registered_tables": "Ainda não registou mesas",
  "how_close_entire_table": "Como fechar a mesa inteira",
  "how_close_individual": "Como fechar as contas individuais",
  "table_settings": "Configurações da Mesa",
  "fee_name_already_exists": "Já existe uma taxa com este nome.",
  "fee_name_cannot_empty": "O nome da taxa não pode estar vazio.",
  "enter_value_greater_than": "Insira um valor maior que",
  "add_fee": "Adicionar Taxa",
  "created_successfully_a": "criado com sucesso",
  "activate_fee": "Ativar Taxa",
  "automatic_charge": "Cobrança Automática",
  "changed_successfully": "alterado com sucesso",
  "delete_fee": "Eliminar Taxa",
  "deleted_successfuly": "eliminado com sucesso",
  "register_waitstaff": "Registar Empregados de Mesa",
  "update_waitstaff": "Atualizar Empregados de Mesa",
  "create_waitstaff": "Criar Empregados de Mesa",
  "edit_waitstaff": "Editar Empregados de Mesa",
  "delete_waitstaff": "Eliminar Empregados de Mesa",
  "deleted_successfully_o": "eliminado com sucesso",
  "restrict_menu": "Restringir Menu",
  "unable_retrieve_menu_information": "Não é possível recuperar informações do menu no momento, por favor tente novamente",
  "creating_fees": "Criando taxas",
  "fee_name": "Nome da Taxa",
  "fee_already_exists": "Esta taxa já existe",
  "type_charge": "Tipo de Cobrança",
  "enter_number_greater_than": "Insira um número maior que",
  "charge_automatically": "Cobrança Automática",
  "deactive": "Desativar",
  "registering_waitstaff": "A Registar Empregados de Mesa",
  "placing_order_waiter": "Fazendo um pedido como empregado de mesa",
  "placing_pizza_order_waiter": "Fazendo um pedido de pizza como empregado de mesa",
  "waitstaff_name": "Nome do Empregado de Mesa",
  "permission": "Permissão",
  "manager": "Gerente",
  "allow_orders": "Permitir Pedidos",
  "register": "Registar",
  "categories": "Categorias",
  "select_categories_waiter_permitted": "Selecione quais categorias o empregado de mesa poderá pedir",
  "call_waitstaff": "Chamar Empregados de Mesa",
  "keep_waitstaff_logged": "Manter empregados de mesa autenticados",
  "change_password": "Mudar a palavra-passe",
  "change_table": "Alterar Mesa",
  "enter_valid_name_different_current": "Por favor, insira um nome válido ou um nome diferente do atual",
  "delete_table": "Eliminar Mesa",
  "activated_a": "ativada",
  "pause_table": "Pausar Mesa",
  "not_possible_pause_table_open": "Não é possível pausar mesas com contas abertas",
  "table_options_up": "OPÇÕES DA MESA",
  "points": "Pontos",
  "settings_up": "CONFIGURAÇÕES",
  "background": "Fundo",
  "resources": "Recursos",
  "register_cashier": "Registar Caixa",
  "registering_operator": "A Registar Operador",
  "placing_order_operator": "Fazendo um pedido como operador",
  "operator_name": "Nome do Operador",
  "operators": "Operadores",
  "box_settings": "Configurações da Caixa",
  "delete_operator": "Eliminar Operador",
  "edit_operator": "Editar Operador",
  "create_operator": "Criar Operador",
  "update_operator": "Atualizar Operador",
  "register_operator": "Registar Operador",
  "there_failure_transaction": "Houve uma falha na transação",
  "change_card": "Alterar Cartão",
  "unable_change_payment_card": "Não é possível alterar o cartão de pagamento.",
  "redirecting_payment_page": "A redirecionar para a Página de Pagamento.",
  "difficulty_generating_payment_screen": "Estamos a ter dificuldades em gerar a tela de pagamento. Por favor, tente novamente.",
  "payment_gateway": "Gateway de Pagamento",
  "no_payment_gateway_found": "Nenhum gateway de pagamento encontrado",
  "subscription": "Subscrição",
  "adding_your_subscription": "Estamos a adicionar a sua subscrição.",
  "failure_adding_your_subscription": "Houve uma falha ao adicionar a sua subscrição",
  "subscription_created_successfully": "A sua subscrição foi criada com sucesso",
  "failure_processing_try_again": "Houve uma falha no processamento. Por favor, tente novamente",
  "ongoing_monthly_invoice": "Há uma fatura mensal em curso.",
  "subscription_canceled": "A sua subscrição foi cancelada",
  "required": "Obrigatório",
  "additional": "Adicional",
  "monthly": "Mensal",
  "annual": "Anual",
  "semiannual": "Semestral",
  "monthly_payment": "Pagamento mensal por cartão",
  "add_registration_service": "Adicionar serviço de registo",
  "with_printer": "Com impressora",
  "select_option": "Selecione uma opção",
  "start_registration": "Iniciar Registo",
  "proceeding_whatsmenu_contact": "Ao prosseguir, concordo que o WhatsMenu me possa contactar por telefone, e-mail ou WhatsApp (incluindo mensagens automáticas para fins comerciais).",
  "message_failure_payment_card": "Houve uma falha no processamento do pagamento. Por favor, verifique os detalhes e assegure-se de que há crédito disponível no seu cartão",
  "owner_name": "Nome do Proprietário",
  "payment_completed_successfully": "Pagamento concluído com sucesso. Vamos recarregar a página.",
  "outstanding_invoices": "Faturas Pendentes",
  "in_progress": "Em Progresso",
  "pay": "Pagar",
  "view_items": "Ver Itens",
  "cart": "Carrinho",
  "check_items": "Verifique os itens",
  "there_no_items_moment": "Não há itens no momento",
  "unique": "Único",
  "hello": "Olá",
  "after_payment_confirmation": "após a confirmação do pagamento, será redirecionado e a página será recarregada.",
  "proceed_payment": "Proceder ao Pagamento",
  "pending_pay": "Pendente",
  "refunded": "Reembolsado",
  "under_review": "Em Revisão",
  "awaiting_confirmation": "Aguardando Confirmação",
  "your_monthly_every_day": "A sua taxa mensal vence todos os dias.",
  "each_month": "cada mês",
  "services": "Serviços",
  "plan": "Plano",
  "generate_invoice": "Gerar Fatura",
  "no_outstandigin_invoices_moment": "Não há faturas pendentes no momento.",
  "paid_invoice_history": "Histórico de Faturas Pagas",
  "detail_table": "Tabela de Detalhes",
  "order_results": "Resultados dos Pedidos",
  "displaying_orders_date_from": "Exibindo pedidos por data a partir de",
  "domain_registered_dns_registration": "Domínio registado com sucesso! Por favor, contacte o suporte para solicitar o registo DNS.",
  "domain_registered": "Domínio registado com sucesso!",
  "domain_already_registered": "Este domínio já está registado",
  "enter_valid_domain": "Por favor, insira um domínio válido",
  "invalid_domain": "Domínio Inválido",
  "domain_not_valid": "Este domínio não é válido. Por favor, insira um domínio válido",
  "domain_successfully_deleted": "Domínio eliminado com sucesso",
  "delete_domain": "Eliminar Domínio",
  "default_domain_successfully_changed": "Domínio padrão alterado com sucesso",
  "dns_entry": "Entrada DNS",
  "dns_entry_successfully": "Entrada DNS eliminada com sucesso",
  "domain_settings": "Configurações de Domínio",
  "after_registering_domain": "Após registar o domínio, terá de o apontar para estes servidores",
  "domain": "Domínio",
  "domain_information": "Informações do Domínio",
  "registered_domains": "Domínios Registados",
  "default_domain_cannot_deleted": "O domínio padrão não pode ser eliminado. Por favor, contacte o suporte",
  "domain_com": "domain.com",
  "default_domain": "Domínio Padrão",
  "monthly_orders_report": "Relatório de Pedidos Mensais",
  "daily_orders_report": "Relatório de Pedidos Diários",
  "box_closures": "Fechamentos de Caixa",
  "box_report": "Relatório de Caixa",
  "customer_report_v2": "Relatório de Clientes",
  "best_selling_report": "Relatório dos Mais Vendidos",
  "best_sellers_v2": "Mais Vendidos",
  "click_here_view_details": "Clique aqui para ver os detalhes",
  "summary_last_months": "Resumo dos últimos 12 meses",
  "summary_last_days": "Resumo dos últimos 30 dias",
  "financial": "Financeiro",
  "ph": "Telefone",
  "dailys": "Diário",
  "monthlys": "Mensal",
  "opening": "Abertura",
  "export_spreadsheet": "Exportar para folha de cálculo",
  "creation": "Criação",
  "period": "Período",
  "delivery_m": "entrega",
  "ready": "Pronto",
  "spreadsheet": "Folha de Cálculo",
  "successfully_generated": "gerado com sucesso",
  "no_orders_found": "Nenhum pedido encontrado",
  "empty": "Vazio",
  "outputs": "Saídas",
  "daily": "Diário",
  "operators_boxes": "Caixas dos Operadores",
  "box_listing": "Lista de Caixas",
  "opening_date": "Data de Abertura",
  "closing_date": "Data de Fechamento",
  "box_closure": "Fechamento de Caixa",
  "paid_a": "Pago",
  "food": "Vale Alimentar",
  "snack": "Vale Refeição",
  "order_printed_please_wait_finish": "Um pedido está a ser impresso, por favor, aguarde até que termine",
  "password_settings": "Configurações de Senha",
  "access": "acesso",
  "security": "segurança",
  "incorrect_current_password": "Senha atual incorreta",
  "register_financial_password": "Registar uma senha financeira",
  "change_financial_password": "Alterar Senha Financeira",
  "register_password_financial": "Registar Senha Financeira",
  "current_password": "Senha Atual",
  "new_password": "Nova Senha",
  "confirm_password": "Confirmar Senha",
  "change_access_password": "Alterar Senha de Acesso",
  "recover_financial_password": "Recuperar Senha Financeira",
  "confirm_new_password": "Confirmar Nova Senha",
  "authentication_failure": "Falha na Autenticação",
  "password_recovery_token": "Token de recuperação de senha expirado ou inválido",
  "use_printer": "Usar Impressora",
  "enable_stock_control": "Ativar Controle de Estoque",
  "stock_control": "Controle de Estoque",
  "layout_options": "Opções de Layout",
  "security_password_registered": "Senha de segurança registada com sucesso",
  "my_name_is": "O meu nome é",
  "contact": "Contato",
  "no_time_up": "SEM TEMPO",
  "with": "com",
  "consult": "Consultar",
  "link_for_order_status": "Link para o status do pedido",
  "chosen_date_earlier_current_date": "A data escolhida é anterior à data atual. Por favor, selecione uma data posterior.",
  "check_the_cvv": "Verifique o CVV",
  "check_card_details": "Verifique os detalhes do cartão",
  "check_billing_address": "Verifique o endereço de cobrança",
  "check_card_expiration_date": "Verifique a data de expiração do cartão",
  "check_card_number": "Verifique o número do cartão",
  "check_security_code": "Verifique o código de segurança",
  "check_zip_code": "Verifique o código postal",
  "unable_register_your_order_try_again": "Não foi possível registar o seu pedido. Por favor, tente novamente.",
  "system_identified_you_are_outside": "O sistema identificou que está fora da nossa área de cobertura. Por favor, tente completar o seu pedido novamente.",
  "swiss_french": "Francês Suíço (Fr.)",
  "swiss_french_chf": "Francês Suíço",
  "set_the_environment": "Defina o ambiente",
  "origin": "Origem",
  "not_received": "Não recebido",
  "marketing_and_sales": "Marketing e Vendas",
  "we_integrate_your_store": "Integramos sua loja as maiores ferramentas de marketing e vendas do mercado, atraia mais clientes e venda muito mais com menos trabalho.",
  "own_website": "Site Próprio",
  "disponibility": "Disponibilidade",
  "please_enter_valid_name": "Por favor insira um nome válido!",
  "image_up": "Imagem com até 8mb (resolução recomendada de 600x450)",
  "share": "Compartilhar",
  "fill_all_required": "Preencha todos os campos obrigatórios",
  "billing": "Faturamento",
  "result_orders": "Resultado Pedidos",
  "viewing_orders_by_date": "Exibindo pedidos por data de",
  "enable_original_image_size": "Ativar tamanho original da imagem",
  "cashback": "Cashback",
  "no_register": "Sem cadastro",
  "load_complements_wait": "Carregando complementos, por favor aguarde",
  "deactivated_delivery": "Desativar Delivery",
  "hide_unity": "Desabilitar quantidade",
  "Delivery_options": "Opções do Delivery",
  "Profile_options": "Opções do Perfil",
  "catalog_mode": "Modo de Catálogo",
  "status_order": "Estado da encomenda",
  "slug": "Slug",
  "clone_categories": "Clonar Categorias",
  "clone": "Clonar",
  "no_change_needed": "Não precisa de troco",
  "serivce_fee": "Taxa de Serviço",
  "customer_delivery_address": "Endereço de Entrega do Cliente",
  "not_informed": "Não informado",
  "route_up": "ROTA",
  "payments_in": "Pagamentos em",
  "the_order_is_now_ready": "o pedido já está pronto para ser retirado! Aqui está o contato do cliente",
  "if_you_need": "caso precise",
  "reference": "Referência",
  "pickup_the_location": "Vou retirar no local",
  "table_request": "Pedido mesa",
  "sounds_notification": "Som de notificação de pedidos",
  "play_sound_delivery": "Ativar som de pedidos de delivery",
  "play_sound_table": "Ativar som de pedidos de mesa",
  "play_sound_package": "Ativar som de pedidos de encomenda"
}
