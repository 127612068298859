import i18n from "i18next";
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from "react-i18next";

// Importar arquivos de tradução
import arAE from './locales/ar-AE.json'
import enGB from './locales/en-GB.json'
import enUS from "./locales/en-US.json";
import frCH from './locales/fr-CH.json'
import ptBR from "./locales/pt-BR.json";
import ptPT from './locales/pt-PT.json'

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  resources: {
    "ar-AE": { translation: arAE },
    "en-GB": { translation: enGB },
    "en-US": { translation: enUS },
    "fr-CH": { translation: frCH },
    "pt-BR": { translation: ptBR },
    "pt-PT": { translation: ptPT },
  },
  fallbackLng: 'pt-BR',

  interpolation: { escapeValue: false },
});

export default i18n;
