{
  "country_code": "41",
  "toMiles": false,
  "neighborhoods": true,
  "currency_config_up": "CHF",
  "masks": {
    "mi": "KM",
    "phone": "00 000 00 00",
    "cell": "00 000 00 00",
    "secret_number": "000.0000.0000.00",
    "ssn_mask": "000.0000.0000.00",
    "zipcode": "0000",
    "date_mask": "dd/MM/yyyy",
    "date_mask_format_m": "DD/MM/YYYY",
    "date_mask_two": "dd/MM",
    "states": []
  },
  "sale_without_registration": "Venda sem cadastro",
  "i_dont_need_indentify_client": "Não preciso identificar o cliente",
  "closing": "Fechamento",
  "uf": "States",
  "order_code": "Código do pedido",
  "favorite_card_comment": "Ex: Meu cartão favorito",
  "finalize_order_comment": "Finalizar Pedido",
  "goodbye": "Ate mais!",
  "opened": "aberto",
  "optional": "Opcional",
  "add_comment": "+Adicionar",
  "n": "Nº",
  "obs": "Observações",
  "my_orders": "meus pedidos",
  "tax_and_time_of_shipping": "taxa e tempo de entrega",
  "scheduling": "Encomendas",
  "scheduling2": "Agendamento",
  "package_s": "Encomendas",
  "close_order": "fechar pedido",
  "verify": "verificar",
  "add_more_items": "adicionar mais itens",
  "next": "Próximo",
  "cart_empty": "Carrinho Vazio",
  "edit_item": "editar item",
  "select_shipoing": "Escolha a Entrega",
  "take_out": "retirar no local",
  "pick_up_counter": "Retirar no Balcão",
  "cupom": "cupom",
  "active_your_cupom": "ativar seu cupom",
  "code_of_cupom": "código do cupom",
  "total": "total",
  "select_the_form_of_shipping": "Escolha a forma de entrega",
  "obrigatory": "obrigatório",
  "go_to_payment": "ir para pagamento",
  "pay_on_app": "pagar no app",
  "pay_on_shippingg": "pagar na entrega",
  "discont": "Desconto",
  "discount_comment": "Desconto",
  "payment_in_comment": "Pagamento em",
  "cash_payment": "pagamento em dinheiro",
  "payment_debit_card": "pagamento em cartão de débito",
  "payment_credit_card": "pagamento em cartão de crédito",
  "i_need_transhipment": "preciso de troco para",
  "i_not_need_transhipment": "Não preciso de troco",
  "secret_number_on_note": "AVS na nota",
  "search_products_by_name_or_description": "Pesquisar Produtos por Nome ou descrição",
  "outside_the_coverage_area": "fora da área de cobertura",
  "unavilable": "Indisponível",
  "avaible": "disponível",
  "product_unavilable": "produto indisponível",
  "unavilable_amount": "quantidade indisponível",
  "table_s": "mesas",
  "discont_of": "desconto de",
  "free_freight": "frete grátis",
  "free_freight_comment": "Frete Grátis",
  "covered_address": "Endereço Fora de Cobertura",
  "send_order": "enviar pedido",
  "you_are_blocked": "você está bloqueado",
  "enter_your_cell": "preencha o Celular/WhatsApp",
  "enter_your_email": "digite seu email",
  "enter_your_address": "digite seu endereço",
  "enter_you_ddd": "Preencha o Celular/WhatsApp com DDD",
  "minimum_unconceived_value": "valor mínimo não atingido",
  "finish_payment_pix": "Finalizar Pagamento Via Twint",
  "finish_payment": "finalizar pagamento",
  "my_cards": "meus cartões",
  "payment_online": "pagamento online",
  "payment_offline": "pagamento na entrega",
  "add_new_card": "adicionar novo cartão",
  "pay_on_delivery": "pagar na entrega",
  "i_need_change_for": "preciso de troco para",
  "i_dont_need_change": "não preciso de troco",
  "send_the_receipt": "envie o comprovante em nosso whatsapp para agilizar o seu atendimento",
  "copy_key": "copiar chave",
  "select_the_flag": "Selecione a bandeira",
  "select_the_flag_comment": "Selecione uma Bandeira",
  "cpf_on_the_receipt": "AVS na nota",
  "how_to_pay_with_pix": "Como pagar no Twint",
  "enter_cpf_generate_pix": "Digite seu AVS para gerar uma chave Twint",
  "enter_cpf": "Digite seu AVS",
  "enter_a_valid_cpf": "Insira um AVS Válido",
  "order_pending_payment": "pedido aguardando pagamento",
  "generate_pix_key": "Gerar chave Twint",
  "pix_copy_paste": "Twint Copia e cola",
  "copy_code": "copiar código",
  "pix_key_expired": "Sua chave Twint expirou. CLique no botão para gerar uma nova",
  "paste_this_code_your_bank": "Cole este código na opção Twint Copia e Cola do seu banco",
  "verify_pix_payment": "Verificar pagamento do Twint",
  "card_details": "dados do cartão",
  "name_on_the_card": "nome no cartão",
  "card_number": "número do cartão",
  "expiry": "validade",
  "card_nickname": "apelido do cartão (opcional)",
  "billing_adress": "endereço da cobrança",
  "zip_code_not_found": "CEP não encontrado",
  "adress_line_2": "complemento (opcional)",
  "order_will_be_delivered": "o pedido será entregue à",
  "to_consult": "À Consultar",
  "flavors_comment": "Sabores",
  "flavor_comment": "Sabor",
  "crust": "Borda",
  "add_ons": "Complementos",
  "add_on": "Complemento",
  "general_toppings": "complementos gerais",
  "single_crust": "borda única",
  "message_cvv_payment": "Digite o código CVV do seu Cartão para finalizar o pagamento",
  "code_back_of_your_card": "este código fica atrás do seu cartão",
  "complete_payment": "finalizar pagamento",
  "what_card_nickname": "qual apelido desse cartão",
  "use_cashback": "utilizar saldo cashback",
  "balance": "saldo",
  "indentify_yourself": "identifique-se",
  "whatsapp_number": "número do whatsapp",
  "number_comment": "Número",
  "date_of_birth": "data de nascimento",
  "place_order_necessary_information": "para realizar o pedido acima, é necessário o preenchimento dos dados acima",
  "order_slip_s": "Comandas",
  "zip_invalid": "CEP inválido",
  "no_active_orders": "sem pedidos ativos",
  "order_sucess": "pedido realizado com sucesso",
  "send_confirmation_whatsapp": "enviar confirmação via whatsapp",
  "select_delivery_date": "selecione uma data para entrega",
  "time_zone_is_different": "Indentificamos que seu fuso horário é diferente do fuso horário do estabelecimento, ao lado do horário estará o seu respectivo horário de recebimento",
  "delivery_time_between": "escolha um horário de entrega entre os periodos",
  "delivery_for_order": "escolha um horário de entrega para sua encomenda",
  "establishment_notes": "observações do estabelecimento",
  "no_more_avaible_slots_for_orders": "não temos mais vagas disponíveis para encomendas, entre em contato com nosso estabelecimento",
  "contact_establishment": "fale com o estabelecimento",
  "no_slots_avaible_for_orders_on_that_day": "não temos mais vagas para encomendas no dia",
  "please_select_another_date": "por favor, selecione outra data.",
  "continue": "continuar",
  "last_orders": "Últimos pedidos",
  "view_order": "ver pedido",
  "edit_addresses": "editar endereços",
  "addresses": "endereço",
  "add_new_addresses": "cadastrar novo endereço",
  "create_address_or_use_list_below": "crie um novo endereço ou use a lista abaixo",
  "more_information": "mais informações",
  "notification_permissions": "para receber notificações é necessário aceitar a permissão de notificações em seu dispositivo.",
  "opening_hours": "horário de funcionamento",
  "from_de": "De",
  "to": "até",
  "pay_online": "pagar online",
  "choose_this_option_secure_payment": "escolha esta opção para realizar o pagamento de forma segura através de nosso sistema online.",
  "choose_option_receiving": "prefere pagar ao receber seu pedido? Escolha esta opção.",
  "acess_your_payment_app": "acesse seu app de pagamentos",
  "qrcode_copy_paste_payment": "escaneie o QR code ou copie e cole o código do pagamento",
  "pay_credited_instantly": "pague e será creditado na hora",
  "enter your cpf": "Digte seu AVS",
  "payment_still_pending": "pagamento ainda pendente. Por favor tente novamente.",
  "confirmation_is_taking_too_long": "se a confirmação estiver demorando muito, clique no botão para continuar.",
  "use_delivery_address": "usar endereço de entrega",
  "state": "estado",
  "payment_meal_voucher": "pagamento em vale refeição",
  "payment_food_voucher": "pagamento em vale alimentação",
  "receipt_to_our_whatsapp": "envie o comprovante em nosso whatsapp para agilizar o seu atendimento",
  "total_to_transfer": "total a transferir",
  "type_of_key": "tipo de chave",
  "save_future_purchases": "salvar para compras futuras",
  "finalize_order": "finalizar pedido",
  "choose_single_crust": "escolha a borda única",
  "choose_single_crust_comment": "Escolha a Borda Única",
  "choose_crust_comment": "Escolha a Borda",
  "crust_without_filling": "sem borda",
  "choose_the": "Escolha a",
  "choose_the_o": "escolha o",
  "attention": "Atenção",
  "complete_order_mandotory": "complete o seu pedido com os itens obrigatórios",
  "no_orders_moment": "sem pedidos no momento",
  "fee_comment": "Taxa",
  "pickup_only": "somente retirada no local",
  "local_pickup": "Retirada no Local",
  "loading_dates": "carregando datas",
  "choose_date": "escolher uma data",
  "see_full_list": "clique aqui para ver a lista completa de produtos",
  "no_products_found": "desculpe, nenhum produto encontrado",
  "size_unavilable": "tamanho indisponível",
  "edit_notes": "editar observações",
  "remove_item": "remover item",
  "point_camera_qrcode": "por favor aponte a sua câmera para o QRCode da mesa em que deseja realizar o pedido",
  "order_status": "status do pedido",
  "in_preparation": "em preparo",
  "canceled": "cancelado",
  "order_received": "pedido recebido",
  "pending_payment": "aguardando pagamento",
  "order_approved": "pedido aprovado",
  "order_preparation": "pedido em preparo",
  "order_canceled": "pedido cancelado",
  "order_out_for_delivery": "pedido saiu para entrega",
  "order_ready_for_pickup": "pedido pronto para retirada",
  "send_order_whatsapp": "enviar pedido via whatsapp",
  "reference_point": "ponto de referência",
  "house_corner": "Casa da esquina",
  "address_complement": "Complemento",
  "block_apt": "Bloco, Apto",
  "ave": "Av. Brasil",
  "date_usa": "dd/mm/yyyy",
  "choose_time": "escolha um horário",
  "minimum_quantity": "Quantidade minima",
  "maximum_quantity": "Quantidade maxima",
  "what_your_name": "Qual o seu nome",
  "open_new_order": "Abrir nova comanda",
  "new_order": "Nova Comanda",
  "repeat_order": "repetir pedido",
  "enter_name_your_order": "Insira um nome para sua comanda",
  "select_order": "Selecione uma comanda",
  "counter_and_delivery": "Balcão/Delivery",
  "discount_of": "desconto de",
  "applied": "aplicado",
  "addition": "Acréscimo",
  "change_address": "mudar endereço",
  "message_no_costumer_selected": "Nenhum cliente selecionado para este pedido, busque por um cliente cadastrado o cadastre um para poder prosseguir.",
  "search_customer": "buscar cliente",
  "add_customer": "cadastrar cliente",
  "outside_service_area": "FORA DA ÁREA DE COBERTURA",
  "free_comment": "Grátis",
  "free_comment_up": "GRÁTIS",
  "activate_coupon_comment": "Ative seu Cupom",
  "order_type": "tipo de pedido",
  "place_order": "fazer pedido",
  "open_register": "abrir caixa",
  "initial_balance_n": "Saldo Inicial",
  "income": "entrada",
  "cash_register_status": "status do caixa",
  "cash_outflow": "saída",
  "cash_outflows": "saídas",
  "cod": "cód.",
  "close_cash_register": "fechar caixa",
  "cash_balance": "saldo dinheiro",
  "cashier_balance": "saldo caixa",
  "final_balance": "saldo final",
  "create_order_slip": "criar comanda",
  "change_tables": "trocar de mesas",
  "table_change": "troca mesa",
  "print_table": "imprimir mesa",
  "close_table": "encerrar mesa",
  "phone_footer": "Telefone",
  "client_comment": "Cliente",
  "no_registred_address": "nenhum endereço cadastrado",
  "none": "nenhum",
  "no_categories_available_at_moment": "Nenhuma categoria disponível para pedidos do tipo mesa no momento.",
  "check_admin_panel_categories_active": "Verifique no painel de administrador se as categorias estão ativas e disponíveis para pedidos de tipo mesa.",
  "search_name_comment": "Buscar Por Nome",
  "search_flavor_comment": "Buscar por Sabor",
  "waiting_service": "aguardando atendimento",
  "pause_comment": "Pausar",
  "unpause_comment": "Despausar",
  "order_buy": "pedir",
  "order_the_table": "pedir na mesa",
  "registration_comment": "Registrando",
  "change_comment": "Alterar",
  "one_more_comment": "Mais um",
  "customer": "clientes",
  "customer_name_comment": "Nome do cliente",
  "email_example_comment": "cliente@exemplo.com",
  "ssn_model_comment": "756.0000.0000.00",
  "number_model_comment": "(000) 000-00-00",
  "order_by": "Pedido por",
  "delivery_address": "Endereço de Entrega",
  "no_number": "sem número",
  "dont_know_my_zip": "Não sei meu CEP",
  "other_s": "Outras",
  "deliver_this_address": "Entregar neste endereço",
  "out_coverage_area": "fora da área de cobertura",
  "error_generating_qrcode": "Erro ao gerar QR Code. Por favor, tente novamente.",
  "finalize_appointment": "fechar agendamento",
  "payment_not_detected_check_banking": "Pagamento não detectado. por favor verifique o aplicativo do seu banco.",
  "error_processing_payment": "Erro ao processar o pagamento, por favor tente novamente.",
  "error_creating_customer": "Erro ao criar cliente. Por favor tente novamente.",
  "return_cancel": "Voltar/Cancelar",
  "return": "Voltar",
  "remaining": "faltam",
  "fee_discount": "Taxa/Desconto",
  "how_pay_pix": "Como pagar no Twint",
  "step": "passo",
  "payment_deadline": "o tempo para pagamento acaba em",
  "select_comment": "Selecione",
  "call_back_house": "Ex: Chamar na Casa dos Fundos",
  "change_for_comment": "Troco Para",
  "for": "para",
  "out for delivery": "saiu para entrega",
  "waiting_for_pickup": "aguardando retirada",
  "add_flavor": "adicionar sabor",
  "your_flavor": " seu sabor",
  "choose": "Escolha",
  "selected_date_is_earlier": "a data escolhida é anterior à atual. Por gentileza, escolha outro horário",
  "register_closing": "fechamento de caixa",
  "confirm_register_closing": "confirmar fechamento de caixa",
  "closing_insert_following": "ao realizar o fechamento, insira os seguintes valores na gaveta do seu caixa",
  "register_login": "login caixa",
  "deposit": "Depósito",
  "purpose": "finalidade",
  "list": "lista",
  "make_payment": "fazer pagamento",
  "uncancel_order": "descancelar pedido",
  "order_amount": "valor total do pedido",
  "print_the": "realizar impressão da",
  "next_flavor_comment": "Próximo Sabor",
  "choose_flavor_comment": "Escolha o Sabor",
  "quantity_unavaible": "Quantidade Indisponível",
  "complete_toppings_comment": "Complete os Complementos",
  "out_stock_toppings_comment": "Complementos Sem Estoque",
  "in_stock_comment": "Em Estoque",
  "the_flavors_comment": "os Sabores",
  "the_crust_comment": "as Bordas",
  "the_toppings_comment": "os Complementos",
  "mandatory_to_choose": "é obrigatório escolher",
  "time_closed_comment": "Horário Encerrado!",
  "change_less_purchase_comment": "Troco abaixo do valor da compra",
  "free_shipping_comment": "FRETE GRÁTIS",
  "consult_comment": "Consultar",
  "location_comment": "Local",
  "paid_online_comment": "Pago Online",
  "track_order": "Acompanhar Pedido",
  "street": "Rua",
  "order_comment": "Encomenda",
  "schedule": "Agendar",
  "closed_orders": "Fechado Encomendas",
  "scheduling_closed": "Agendamento Fechado",
  "order_name": "nome da comanda",
  "no_product_found": "nenhum produto encontrado",
  "items_found": "itens encontrados",
  "mandatory_items": "itens obrigatorios",
  "minimum": "minímo",
  "advance": "Avançar",
  "add_another_card": "adicionar outro cartão",
  "loading_page": "Carregando...",
  "enter_city": "Digite a cidade",
  "select_city": "Selecione a cidade",
  "enter_neighborhood": "Digite o bairro",
  "select_neighborhood": "Selecione o bairro",
  "enter_street_name": "Digite o nome da rua",
  "coupon_only_used_purchases": "Esse cupom só pode ser usado em vendas a partir de",
  "discount_coupon_greater_total": "O desconto no cupom é maior do que o valor total!",
  "add_to_order": "Adicionar para encomenda",
  "data_print": "Data de impressão",
  "generate_fiscal_note": "Realizar Emissão de nota fiscal da",
  "alert": {
    "unable_to_read": "Não é possível ler a sua localização, verifique se a localização não está bloqueada.",
    "address_covarage_area": "Seu endereço está fora da nossa área de cobertura!",
    "register_open_24": "Existe um caixa aberto há mais de 24 horas e ele será encerrado",
    "table_desactived": "Desculpe está mesa se encontra desativada!",
    "moment_table_not_avaible": "No momento, está mesa não está disponível para novos pedidos",
    "table_not_avaible_new_orders": "No momento, essa mesa não está disponível para novos pedidos.",
    "order_already_been_completed": "A comanda anterior já foi finalizada, você ainda esta no restaurante ou deseja fazer um novo pedido para entrega?",
    "at_the_table": "Estou na mesa",
    "deliver_order": "Entregar pedido",
    "not_belong_this_table": "Esta comanda não pertence a esta mesa",
    "store_closed": "LOJA FECHADA!",
    "not_minimum_value": "Seu pedido não atingiu o valor mínimo de",
    "failed_validate_coupon": "Falha ao validar cupom, verifique sua conexão!",
    "amount_not_include_delivery_fee": "Este valor total não inclui a taxa de entrega.",
    "coupon_used_starting_from": "Esse cupom só pode ser usado em compras a partir de",
    "closed_delivery": "FECHADO PARA DELIVERY",
    "unable_create_customer": "Não foi possível criar cliente. Por favor tente novamente.",
    "selected_date_earlier_choose_later_date": "A data escolhida é menor que a data atual,<br>favor escolher uma data maior.",
    "try_again_moment": "Algo inesperado aconteceu, tente novamente em instantes",
    "error_generate_qrcode": "Não foi possível gerar o QR Code. Por favor, tente novamente.",
    "payment_not_detected": "Pagamento não detectado. por favor verifique o aplicativo do seu banco.",
    "reloaded_page_message": "A página precisa ser recarregada!",
    "name_and_phone_required": "Nome e telefone são obrigatórios",
    "enter_your_details": "Insira seus dados",
    "enter_your_name": "Insira seu nome",
    "enter_your_whatsapp": "Insira seu WhatsApp",
    "enter_your_date_of_birth": "Insira sua data de nascimento",
    "review_change_amount": "Revise o valor do troco!",
    "no_internet_connection": "Sem conexão com a internet!"
  },
  "calendar": {
    "weekdays": ["Seg", "Ter", "Qua", "Qui", "Sex", "Sáb", "Dom"],
    "months": [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ]
  },
  "timezones": [
    {
      "label": "Zurich",
      "value": "Europe/Zurich"
    },
    {
      "label": "Paris",
      "value": "Europe/Paris"
    }
  ],
  "unitSystem": "imperial",
  "distanceinkmto": "Distância em KM até",
  "distance": "Distância",
  "distance_added_successfully": "Distância adicionada com sucesso!",
  "the_distance_already_exists": "Essa distância ja existe",
  "distance_deleted_successfully": "Distância excluída com sucesso!",
  "update_shipping": "Atualizar frete",
  "tobeconfirmed": "A Consultar",
  "km": "KM",
  "time": "Tempo",
  "zelle": "Zelle",
  "twint": "Twint",
  "coin": "CHF",
  "currency_config": "chf",
  "ddi": "41",
  "date_format": "dd/MM/yyyy",
  "date_format_v2": "dd/MM",
  "welcome": "Bem vindo!",
  "description": "Descrição",
  "orders": "Pedidos",
  "order": "Pedido",
  "order_management": "Gestão de pedidos",
  "new_feature": "Recurso novo",
  "work_waiters_taking": "Você trabalha com garçons tirando pedidos?",
  "register_you_waiters_quickly": "Então Cadastre seus garçons e lance pedidos rapidamente.",
  "dont_work_waiters": "Não trabalho com garçons",
  "register_waiters": "Cadastrar meus garçons",
  "orders_were_not_received": "Pedidos que não foram recebidos.",
  "orders_appointments": "Encomendas/Agendamentos",
  "efficiency": "Agilidade",
  "incredible_agility": "Incredible agility in table and order management never seen before; this system is everything you’ve always dreamed of to earn more money and organize your restaurant.",
  "automation": "Automatização",
  "now_you_control": "Agora você controla as mesas e comandas do seu restaurante de forma automática, sem erros ou mão de obra extra para tirar os pedidos.",
  "qrcode_self_service": "Tecnologia QRCode de auto-atendimento por mesa, não precisa do garçom tirador de pedidos.",
  "self_service": "Auto-atendimneto",
  "customers_place_orders_themselves": "Com este auto-atendimento os seus clientes pedem sozinhos na mesa e os pedidos são impressos automaticamente direto na sua cozinha por ordem na fila.",
  "zero_waste": "Zero perda",
  "indecipherable_handwriting": "Chega daquelas letras indecifráveis, erros e prejuízos no preparo dos pratos, mantenha tudo funcionando com muita agilidade no seu restaurante.",
  "closure": "Encerramento",
  "automatically_separates_calculates": "Fechamento rápido da conta direto na mesa ou no caixa, o WhatsMenu separa e calcula sozinho o que cada comanda consumiu permitindo cobrar individualmente o cliente que vai embora antes da mesa ser encerrada, permitindo fechamentos com tranquilidade e livre de erros.",
  "ordering_system": "O Sistema de Encomenda",
  "allows_your_customer": "Permite ao seu cliente escolher o dia e horário para receber seu pedido.",
  "allows_customer_they_wish": "Permite que o cliente escolha o dia e horário em que deseja receber o pedido.",
  "you_can_sell_24h": "Você pode vender 24h, mesmo com seu estabelecimento fechado.",
  "prevents_setbacks": "Evita contratempos, como o cliente não estar disponível para receber o pedido e o entregador perder a viagem.",
  "advantages": "Vantagens",
  "configure_days_establishment": "Configure os dias e horários aceitos pelo estabelecimento.",
  "customer_best_time_for_them": "O cliente escolhe o melhor horário para ele",
  "helps_improve": "Ajuda à melhor organização na logística de entrega",
  "speak_with_consultant": "Falar com consultor agora",
  "must_be_between": "O valor deve estar entre 0.49 e 0.99",
  "acess": "Acesse",
  "delete_addresses": "Excluir Endereços",
  "addresses_deleted": "Endereços excluídos com sucesso!",
  "control_of": "Controle de",
  "error_deleting_addresses": "Erro ao excluir endereços. Verifique o console para mais detalhes.",
  "user": "Usuário",
  "sucess_updated": "atualizado com sucesso",
  "something_wrong": "Deu ruim",
  "talk_it_team": "Falar com o pessoal do T.I",
  "user_profile": "Perfil do Usuário",
  "profile": "Perfil",
  "asaas_account_disconnected": "Conta Asaas desvinculada do Perfil",
  "notes": "Notas",
  "unable_disconnect_asaas_account": "Não foi possível desvincular a Conta Asaas do Perfil",
  "additionals": "Adicionais",
  "there_no_services_generate": "Não há serviços para gerar",
  "boleto_generated": "Boleto gerado com sucesso",
  "issue_add_ons": "Emitir Adicionais",
  "make_sure_boleto_generated": "Certifique-se que foi gerado um boleto para esta fatura",
  "invoice_settlement": "Baixa de Fatura",
  "reset_security_key": "Resetar chave de segurança",
  "assign_support": "Atribuir Suporte",
  "acessing_user_account": "Acessando conta do usuário",
  "logging_in": "Logando...",
  "back_to_list": "Voltar para lista",
  "menu": "Cardápio",
  "client_contracted_menu_registration": "Cliente contratou cadastro de cardápio",
  "printer": "Impressora",
  "client_purchased_printer": "O cliente comprou impressora",
  "user_data": "Dados do Usúario",
  "salesperson": "Vendedor",
  "responsible_support": "Suporte Responsável",
  "name": "Nome",
  "no_name": "Sem nome",
  "no_email": "Sem email",
  "invalid_email": "Email inválido",
  "owner_email_address": "Email do dono do estabelecimento",
  "ssn_ein": "AVS",
  "no_ssn_ein": "Sem AVS",
  "invalid": "Inválido",
  "no_whatsapp": "Sem WhatsApp",
  "full_name": "Nome completo",
  "register_customer": "Cadastrar Cliente",
  "registration_date": "Data de Cadastro",
  "no_date": "Sem data",
  "select_payment_gateway_for_user": "Selecione o gateway de pagamento para o usuário antes de continuar",
  "login_attempts": "Tentativas de Login",
  "due_date": "Vencimento",
  "payment_method": "Forma de Pagamento",
  "card": "Cartão",
  "password": "Senha",
  "passwords": "Senhas",
  "enter_new_password": "Digite a nova senha",
  "security_key": "Chave de segurança",
  "reset": "Resetar",
  "access_panel": "Acessar Painel",
  "whatsapp": "Whatsapp",
  "language": "Idioma",
  "country": "Pais",
  "brazil": "Brasil (R$)",
  "united_states": "Estados Unidos ($)",
  "united_kingdom": "Inglaterra (£)",
  "portugal": "Portugal (€)",
  "select_language": "Selecione o Idioma",
  "currency": "Moeda",
  "select_currency": "Selecione a Moeda",
  "portuguese_brazil": "Português (Brasil)",
  "portuguese_portugal": "Português (Portugal)",
  "english_us": "Inglês (US)",
  "english_uk": "Inglês (Grã-Bretanha)",
  "english_ar": "Inglês (Dubai)",
  "internationalization": "Internacionalização",
  "not_registered": "Não Cadastrado",
  "not_have_permission_adm": "Você não tem permissão para acessar uma conta Administradora",
  "clear_addresses": "Limpar Endereços",
  "clear": "Limpar",
  "delete_addresses_customer": "Você deseja excluir os endereços desse cliente?",
  "no": "Não",
  "yes": "Sim",
  "user_cancellation": "Cancelamento Usuário",
  "cancel_charges_user": "Cancelar cobranças deste usuário?",
  "version_next": "Versão Next",
  "test": "teste",
  "activate": "Ativar",
  "automatic_printing_browser": "Impressão Automática pelo navegador",
  "observations": "Observações",
  "save": "Salvar",
  "title": "Título",
  "block_profile": "Bloquear Perfil",
  "value_whatsapp": "Valor no Whatsapp",
  "enable_shipping_mi": "Habilitar frete por KM",
  "double_sending_whatsapp": "Duplo envio Whatsapp",
  "enable_pix_online": "Habilitar Twint Online",
  "enable_online_card": "Habilitar Cartão Online",
  "old_pix": "Twint antigo",
  "negotiation_fee": "Taxa negociação",
  "days": "dias",
  "expires_in": "Expira em",
  "expired_since": "Expirou a",
  "disconnect_account_asaas": "Desvincular Conta Asaas",
  "unlink": "Desvincular",
  "account_asaas": "Conta Asaas",
  "unlink_asaas_account": "Você deseja desvincular a conta Asaas desse cliente",
  "quantity": "Quantidade",
  "issue": "Emitir",
  "number_installments": "Quantidade de Parcelas",
  "invoices": "Faturas",
  "transaction_id": "ID da Transação",
  "value": "Valor",
  "action": "Ação",
  "action_remove_user_the": "Esta ação irá remover usuário da",
  "notice": "Aviso",
  "settlement": "Baixa",
  "warning_financial_password": "ATENÇÃO! Você precisa Cadastrar uma Senha Financeira.",
  "warning_v2": "Para isso acesse no menu a opção Configurações",
  "passwords_or": "Senhas ou",
  "click_here": "Clique Aqui",
  "enter_financial_password": "Digite sua Senha Financeira",
  "confirm": "Confirmar",
  "forgot_financial_password": "Esqueceu sua Senha Financeira",
  "recover_password": "Recuperar Senha",
  "check_inbox_spam": "verifique na 'caixa de entrada' ou 'spam'",
  "message_subject": "a mensagem com o assunto",
  "password_recovery": "Recuperação de Senha",
  "whastmenu": "Whatsmenu",
  "start_date": "Data Inicial",
  "end_date": "Data Final",
  "search": "Buscar",
  "print": "Imprimir",
  "report": "Relatório",
  "product": "Produto",
  "products": "Produtos",
  "product_m": "produto",
  "qty_sold": "Qto. Vendido",
  "total_amount": "Valor Total",
  "cash_summary": "RESUMO CAIXAS",
  "qty": "Qntd.",
  "incomes": "Entradas",
  "table_closing": "Encerramento mesa",
  "table": "Mesa",
  "counter": "Balcão",
  "request": "Encomenda",
  "cancelled": "Cancelados",
  "cancelled_up": "CANCELADO",
  "cancelled_o": "Cancelado",
  "outflows": "Saídas",
  "n_orders": "Nº de Pedidos",
  "client": "Cliente",
  "average_ticket": "Ticket Médio",
  "total_spent": "Total Gasto",
  "total_orders": "Total de pedidos",
  "total_amount_spent": "Valor total gasto",
  "customer_report": "Relatório do cliente",
  "number_of_orders": "Qtd. de Pedidos",
  "order_history": "Histórico de Pedidos",
  "order_cod": "Cod. Pedido",
  "change_for": "Troco Para",
  "change": "Troco",
  "payment": "Pagamento",
  "all_customers": "Todos Clientes",
  "filter": "Filtrar",
  "order_volume": "Volume de Pedidos",
  "amount_consumed": "Valor Consumido",
  "no_results_found": "Nenhum resultado encontrado",
  "no_customer_found": "Nenhum cliente encontrado com esse nome",
  "phone_number": "Telefone",
  "customer_found": "Clientes Encontrados",
  "opening_time_must_closing": "O horário de abertura tem que ser maior que o de fechamento!",
  "time_will_not_take": "Esse horário não surtirá efeito no",
  "following": "Seguinte",
  "day": "Dia",
  "changing_existing_time": "considere alterar um horário ja existente no",
  "mentioned": "Citado",
  "please_select_day": "Por favor selecione um dia!",
  "please_set_valid_time": "Por favor Defina um horário válido!",
  "operating_hours": "Horário de funcionamento",
  "the_change_were": "As alterações não foram feitas pois há horários inválidos. Por favor corrija os campos destacados e tente novamente",
  "product_availability": "Disponibilidade de produtos",
  "setting_operating_hours": "Cadastrando horários de funcionamento",
  "choose_days_times": "ESCOLHA OS DIAS E OS HORÁRIOS",
  "select_all": "Selecionar Todos",
  "time_zone_successfully_changed": "Fuso horário alterado com sucesso",
  "from": "Das",
  "until": "Até",
  "add": "Adicionar",
  "open": "Abrir",
  "close": "Fechar",
  "actions": "Ações",
  "invalid_time": "Horário inválido",
  "if_work_overnight": "Caso você trabalhe também de madrugada, ou seja após meia noite, cadastre desta maneira",
  "pause": "Pausar",
  "unpause": "Despausar",
  "delete": "Excluir",
  "type": "Tipo",
  "priority": "Prioridade",
  "email_server": "Servidor de Email",
  "content": "Conteúdo",
  "destination": "Destino",
  "address": "Endereço",
  "discount": "Desconto",
  "surchage": "Acréscimo",
  "fixed": "Fixo",
  "percentage": "Percentual",
  "type_of_fee": "Tipo de Taxa",
  "type_of_value": "Tipo de Valor",
  "fee_amount": "Valor da Taxa",
  "copy": "CÓPIA",
  "successfully_duplicated": "foi duplicada com sucesso",
  "not_possible_duplicate": "Não foi possível duplicar a categoria",
  "unable_duplicate_product": "Não foi possível duplicar o produto",
  "category": "Categoria",
  "successfully": "com sucesso",
  "the_category": "a categoria",
  "could_not": "Não foi possível",
  "paused": "Pausado",
  "unpaused": "Despausado",
  "the_product": "o produto",
  "sizes": "Tamanhos",
  "size": "Tamanho",
  "edges_doughs": "Bordas e Massas",
  "flavors": "Sabores",
  "menu_information": "Informações do Cardápio",
  "cat_standard": "Cat. Padrão",
  "qty_product": "Qtd. Produto",
  "qty_add_ons": "Qtd. Complementos",
  "qty_size": "Qtd. Tamanhos",
  "qty_edges_doughs": "Qtd. Bordas/Massas",
  "qty_flavors": "Qtd. Sabores",
  "you_edited": "Você editou",
  "items": "itens",
  "cancel_edit_message": "Deseja cancelar a edição",
  "there_are_no": "Não há",
  "to_update": "para atualizar",
  "package": "Encomendas",
  "appointments": "Agendamentos",
  "appointment": "Agendamento",
  "share_facebook": "Compartilhar no Facebook",
  "copy_clipboard": "Copiar para o clipboard",
  "copied": "Copiado",
  "link_sucess_copied": "Link copiado com sucesso.",
  "unlink_link_copied": "Não foi possível copiar o link, seu navegador não tem suporte.",
  "message_menu_adjust_prices": "O cardápio é sua vitrine de produtos no WhatsMenu. Aqui você pode criar categorias, inserir itens, complementos e opcionais, definir disponibilidade e alterar preços.",
  "add_category": "Adicionar Categoria",
  "reorder_menu": "Reordernar Cardápio",
  "search_g": "Pesquisar",
  "search_place": "Pesquisar...",
  "complements": "Complementos",
  "complement": "Complemento",
  "pizza_flavors": "Sabores de Pizza",
  "duplicate": "Duplicar",
  "edit": "Editar",
  "stock": "Estoque",
  "low": "Baixo",
  "out_stock": "Esgotado",
  "edit_cover": "Editar Capa",
  "add_size": "Adicionar Tamanho",
  "add_item": "Adicionar Item",
  "add_complements": "Adicionar Complemento",
  "edit_flavors": "Editar Sabores",
  "cancel_edit": "Cancelar Edição",
  "prices": "Preços",
  "price": "Preço",
  "photo": "Foto",
  "discard_changes": "Descartar alterações",
  "enter_valid_value": "Por favor insira um valor válido!",
  "in_stock": "EM ESTOQUE",
  "add_flavors_firt_pizza": "Para adicionar sabores, crie um tamanho de pizza primeiro.",
  "add_flavors": "Adicionar Sabores",
  "updated_now": "Atualizado agora",
  "flavors_will_be": "sabores serão",
  "flavor_will_be": "sabor será",
  "please_wait": "Aguarde...",
  "please_wait_n": "Aguarde",
  "edit_products": "Editar Produtos",
  "products_will_be": "produtos serão",
  "product_will_be": "produto será",
  "no_results_your_search": "Nenhum resultado para sua pesquisa",
  "no_category_registred": "Nenhuma categoria cadastrada",
  "add_items_bulk": "Adicionar itens em massa",
  "you_changing_promotional_price": "Você está alterando o valor promocional do produto.",
  "system": "Sistema",
  "cash_detail": "Detalhamento caixa",
  "operator": "Operador",
  "image_preview": "Pré-Visualização de Imagem",
  "width": "Largura",
  "height": "Altura",
  "complete": "Finalizar",
  "review_entered_data": "Por favor, revise os dados inseridos.",
  "confirm_financial_password": "Confirmar Senha Financeira",
  "financial_password": "Senha Financeira",
  "how_to_make": "Como Fazer",
  "updated": "atualizada",
  "updated_o": "atualizado",
  "created": "criada",
  "unable_delete_category": "Não foi possível excluír a categoria",
  "standard": "Padrão",
  "delete_category": "Excluir a categoria",
  "review_data": "Revise os Dados",
  "details": "Detalhes",
  "availability": "Disponibilidade",
  "select_category_model": "Selecione o modelo da categoria",
  "acai_ice_creams": "Açaís e Sorvetes",
  "burgers": "Hambúrgueres",
  "meal_boxes": "Marmitas",
  "meal_boxes_size_options": "Marmitas com escolha de tamanhos",
  "build_meal_box": "Monte sua marmita",
  "butcher_shop": "Açougue",
  "produce_section": "Hortifruit",
  "drinks": "Bebidas",
  "category_name": "Nome da Categoria",
  "invalid_category_name": "Nome da categoria inválido",
  "characters": "caracteres",
  "minimum_stock": "Estoque Mínimo",
  "add_availability_hours": "Adicionar Horário de Disponibilidade",
  "use_addons_another_item": "Usar complementos de outro item",
  "creating_addons": "Criando complementos",
  "shared_addons": "Complementos compartilhados",
  "attention_up": "ATENÇÃO",
  "all_changes_addon_affect_other_product": "Todas alterações feitas nesse complemento terão efeito em outros produtos que o utilizarem",
  "link": "Vincular",
  "attention_addon_linked": "Atenção este complemento está vinculado a",
  "attention_addon_linked_v2": "qualquer alteração feita aqui afetará os produtos vinculados, exceto excluir.",
  "other": "outro",
  "view_linked_products": "Ver Produtos Vinculados",
  "invalid_name": "Nome inválido",
  "min_greater_max": "Mín maior que o máximo.",
  "max_less_min": "Máx menor que mínimo",
  "mandatory_addon": "Complemento Obrigatório",
  "options": "Opções",
  "please_enter_valid_value": "Por favor, insira um valor válido",
  "always_available": "Sempre disponível",
  "enable_stock": "Habilitar Estoque",
  "created_o": "criado",
  "create": "Criar",
  "was_deleted_successfully": "foi deletado com sucesso",
  "could_not_delete_flavor": "Não foi possível deletar o sabor",
  "you_really_want_delete": "Deseja realmente excluir",
  "error": "erro",
  "the_flavor": "o sabor",
  "flavor": "Sabor",
  "image_up_8": "Imagem com até 8mb",
  "add_image": "Adicionar Imagem",
  "recomended_resolution_600": "resolução recomendada de 600x450",
  "please_valid_name": "Por favor insira um nome válido",
  "message_pause_sales": "Para pausar as vendas deste item, clique no botão ao lado. Se o botão estiver habilitado, o item não aparecerá na sua lista de pratos.",
  "pause_sales": "Pausar Vendas",
  "resume_sales": "Despausar Vendas",
  "created_successfully": "criado com sucesso.",
  "updated_successfully": "atualizada com sucesso.",
  "was_not_created": "não foi criada.",
  "was_not_updated": "não foi atualizada.",
  "deleted_successfully": "deletada com sucesso.",
  "could_not_delete": "Não foi possível deletar",
  "could_not_pause": "Não foi possivel pausar",
  "resumed_successfully": "despausada com sucesso",
  "paused_successfully": "pausada com sucesso",
  "to_pause_sale_message": "Para pausar as vendas deste item, clique no botão ao lado. Se o botão estiver habilitado, o item não  aparecerá na sua lista de pratos.",
  "size_name_already_exists": "Já existe um tamanho com este nome",
  "the_size": "o tamanho",
  "update": "atualizar",
  "message_delete_remove_another": "Para excluir é necessário apagar os sabores ou adicionar outro tamanho de pizza",
  "message_really_delete_size": "Deseja realmente excluir o tamanho",
  "message_could_not_delete": "Não foi possível excluír o tamanho",
  "edit_size": "Editar Tamanho",
  "cover": "Capa",
  "this_size_accepts": "Esse tamanho aceita",
  "add_cover": "Adicionar Capa",
  "message_cannot_invalid_times": "As alterações não podem ser feitas pois há horários inválidos. Por favor corrija os campos destacados e tente novamente",
  "review_not_allowed": "Revise seus complementos, não é permitido nome vázio",
  "could_not_delete_product": "Não foi possível excluir o produto",
  "delete_the_product": "Excluir o produto",
  "promotion": "Promoção",
  "activate_promotion": "Ativar promoção",
  "button_enable_disable_promotion": "Use o botão ao lado para habilitar ou desabilitar a promoção.",
  "original_price": "Valor Original",
  "promotional_price": "Valor Promocional",
  "available_for": "Disponível para",
  "message_products_not_created": "Os Produtos não foram criados pois há horários inválidos. Por favor corrija os campos destacados e tente novamente",
  "products_created_successfully": "Todos os produtos foram criados com sucesso.",
  "products_not_created": "Produtos não foram criados.",
  "create_products_bulk": "Criar Produtos em Massa",
  "shortcuts": "Atalhos",
  "right_arrow": "Seta Direita",
  "left_arrow": "Seta Esquerda",
  "next_step": "Próximo Passo",
  "back_one_step": "Voltar um Passo",
  "create_products": "Criar Produtos",
  "add_product": "Adicionar Produto",
  "duplicate_product": "Replicar Produto",
  "product_name": "Nome do Produto",
  "table_price": "Preço Mesa",
  "product_description": "Descrição do Produto",
  "duplicate_r": "Replicar",
  "no_product_added": "Nenhum produto adicionado",
  "creating_products": "Criando Produtos...",
  "reordered_successfully": "Reordenado com successo",
  "could_not_reorder_items": "Não foi possível reordernar os itens",
  "message_press_and_hold": "Aperte e segure por 1 segundo e arraste os itens para posição desejada, esta sequência também refletirá na sua loja.",
  "reordegring_wait": "Reordenando, aguarde...",
  "you_any_categories_yet": "Você ainda não cadastrou nenhuma categoria.",
  "please_review_data": "Por Favor, revise os dados.",
  "review": "Revisão",
  "fee_shipping": "Frete Grátis",
  "fee_shipping_up": "FRETE GRÁTIS",
  "fee": "Taxa",
  "disc": "Desc.",
  "free": "Grátis",
  "be_determined": "À Consultar",
  "disabled": "Desativada",
  "order_slip": "Comanda",
  "order_slips": "Comandas",
  "ssn": "AVS",
  "duration": "Permanência",
  "delivery_date": "Data de Entrega",
  "no_time_up": "SEM HORÁRIO",
  "paid_up": "PAGO",
  "coupon_used": "Cupom usado",
  "delivery": "Entrega",
  "coupon": "Cupom",
  "payment_in": "Pagamento em",
  "total_paid": "Total Pago",
  "checkout": "Fechamento",
  "paid_online_up": "PAGO ONLINE",
  "delivery_person": "Entregador",
  "s_n": "S/N",
  "technology": "Tecnologia",
  "missing": "Faltam",
  "waiter": "Garçom",
  "paid": "Pago",
  "delivery_fee": "Taxa de Entrega",
  "printing_error": "Erro na impressão",
  "could_not_send_printing": "Não foi possível enviar a cópia para impressão.",
  "order_confirmation": "Confirmação de pedido",
  "could_not_print_order": "Não foi possivel realizar a confirmação de impressão do pedido",
  "change_date": "Alterar Data",
  "pending_prints": "Impressões Pendentes",
  "message_pending_print": "pedidos pendentes para impressão, deseja realizar a impressão destes pedidos?",
  "there_are": "Há",
  "print_all": "Imprimir Todos",
  "could_not_play_sound": "Não foi possível reproduzir o som.",
  "printing_up": "IMPRIMINDO",
  "cancel_order": "Cancelar Pedido",
  "reinstate_order": "Descancelar Pedido",
  "no_orders_print_up": "NÃO HÁ PEDIDOS PARA IMPRIMIR",
  "order_preview": "Pré Visualização do Pedido",
  "teste_print_order": "Pedido para Teste de Impressão",
  "report_request": "Pedido de Relatório",
  "received": "Recebido",
  "preparation": "Preparo",
  "delivering": "Entregando",
  "served": "Servido",
  "ready_for_pickup": "Pronto Retirar",
  "speak_customer": "Falar com Cliente",
  "select": "Selecione",
  "add_address": "Adicionar Endereço",
  "edit_address": "Editar Endereço",
  "filling_establishment_address": "Preenchendo o Endereço do Estabelecimento",
  "zip_code": "NPA",
  "invalid_zip_code": "NPA inválido",
  "number": "Número",
  "neighborhood": "Bairro",
  "city": "Cidade",
  "enter_registed_email_recover_password": "Digite seu email de cadastro para recuperar sua senha",
  "please_enter_valid_email": "Insira um email válido",
  "email_sent_successfully": "Email enviado com sucesso",
  "send": "Enviar",
  "delivery_package": "DELIVERY/ENCOMENDAS",
  "change_order_date": "Alterar Data Pedido",
  "selected_date": "Data Escolhida",
  "current_order_date": "Data Atual do Pedido",
  "distribution": "Distribuição",
  "order_coder": "Código do Pedido",
  "no_order_selected": "Nenhum Pedido Selecionado",
  "go_back": "Voltar",
  "pickup": "Retirada",
  "deliver": "Entregar",
  "no_items_this_date": "Nenhum item para esta data",
  "all": "Todos",
  "pending": "Em Espera",
  "marked_received": "Marcados C/ Recebidos",
  "local_delivery": "Entrega Local",
  "summary_of": "Resumo de",
  "your": "seus",
  "your_a": "suas",
  "filter_use": "Filtro em uso",
  "choose_date_summary": "Escolha uma data para resumo",
  "printing": "Impressão",
  "selected_orders": "Pedidos Selecionados",
  "regarding": "Referente",
  "calendar_view": "Modo Calendário",
  "loading_orders": "Carregando pedidos...",
  "order_slip_fees": "Taxas Comanda",
  "fees": "Taxas",
  "charge": "Cobrar",
  "next_confirm": "Próximo/Confirmar",
  "back_cancel": "Voltar/Cancelar",
  "proceed": "Prosseguir",
  "delivery_report": "Relatório de Entregadores",
  "qty_of_deliveries": "Qtd. Entregas",
  "total_delivery_fees": "Total Taxas de Entregas",
  "delivery_report_r": "Relatório de Entregas",
  "code_order": "Cod. Pedido",
  "date": "Data",
  "discard": "Descartar",
  "pending_changes": "Alterações Pendentes",
  "you_whant_save_them": "Existem alterações não salvas, deseja salvar?",
  "new": "Novo",
  "my_profile": "Meu Perfil",
  "delivery_drivers": "Entregadores",
  "tables": "Mesas",
  "no_active_waiter": "Nenhum garçom ativo",
  "message_register_waitr_enable": "Para registrar um garçom ou habilitar um já existente vá em Configurações > Mesa > na aba de Garçons, ou clique em",
  "register_waiter": "Cadastrar Garçom",
  "coupons": "Cupons",
  "social_media": "Mídia Social",
  "reports": "Relatórios",
  "financial_report": "Relatório Financeiro",
  "daily_orders": "Pedidos Diários",
  "monthly_orders": "Pedidos Mensais",
  "cash_register_closing": "Fechamento Caixa",
  "best_sellers": "Mais Vendidos",
  "customer_report_side": "Relatório Clientes",
  "general": "Gerais",
  "cash_registers": "Caixas",
  "domains": "Domínios",
  "payment_methods": "D. de Pagamento",
  "administrator": "Administrador",
  "support_bonus": "Bônus Suporte",
  "salesperson_report": "Relatório Vendedor",
  "annual_report": "Relatório Anual",
  "updates": "Atualizações",
  "exit": "Sair",
  "support_hours": "De Seg à Sáb das",
  "settings": "Configurações",
  "phone": "Telefone",
  "press": "Aperte",
  "order_already_printed": "Pedido já foi impresso",
  "order_not_printed": "Pedido não impresso",
  "wait_order_printed": "Aguarde o pedido ser impresso",
  "displaying": "Exibindo",
  "of": "de",
  "filter_o": "Filtro",
  "caption": "Legenda",
  "order_list": "Lista de pedidos",
  "message_download_whatsmenu_desktop": "Baixe agora o NOVO WhatsMenu Desktop",
  "message_less_work": "Menos Trabalho e Mais Vendas",
  "message_virtual_assistant": "Atendente virtual para WhatsApp",
  "with_area_code": "Com DDD",
  "owner_cell_phone": "Celular do dono do estabelecimento",
  "message_loyalty_program": "Programa de Fidelidade",
  "message_first_purchase": "Cupom de primeira compra",
  "message_automated_sales_bot": "Robô de vendas automatizadas",
  "message_status_updates": "Envio de status sem abertura de abas",
  "message_download_app": "Baixar Aplicativo",
  "donwload_the_whatsmenu": "Baixe o WhatsMenu Desktop",
  "message_download_whatsmenu_printers": "Baixe agora o Novo WhatsMenu Impressoras",
  "message_automatic_printing_mobile": "Impressão automática no celular",
  "message_printing_multiple_printers": "Impressão em múltiplas impressoras",
  "message_copy_printing": "Impressão de cópias",
  "message_download_gplay": "Baixar pelo Google Play",
  "message_downlaod_whatsmenu_printers": "Baixe o WhatsMenu Impressoras",
  "message_audio_disabled": "Áudio desabilitado. Por favor interaja com a tela para habilitar.",
  "message_monthly_invoice_available": "Fatura de mensalidade disponível para pagamento! Clique aqui.",
  "message_menu_locked": "Seu cardápio pode ser bloqueado, pois sua mensalidade esta em atraso! Clique aqui.",
  "overview_pos_pizza_addons": "Visão Geral PDV e Adicionais de Pizza WhatsMenu",
  "how_to_register_customer_pos": "Como cadastrar um cliente no PDV",
  "guide_placing_order_pos": "Passo a passo para fazer um pedido via PDV",
  "how_to_update_customer_information_pos": "Como alterar informações do cadastro do cliente via PDV",
  "guide_opening_cash_register": "Passo a Passo para abertura do caixa",
  "guide_closin_cash_register": "Passo a Passo para fechamento de caixa",
  "cash_register_closing_reports": "Relatórios de encerramento de Caixa",
  "how_place_order_counter_pos": "Como fazer um pedido para retirada no balcão via PDV",
  "reordering_customers_orders": "Repetindo os pedidos anteriores de clientes em poucos cliques",
  "place_order_pizza_pos": "Como fazer pedido de pizza de 2 sabores ou mais, bordas e adicionais via PDV",
  "how_create_tables": "Como criar mesas",
  "guide_registering_pizza_crusts": "Passo a passo para cadastrar pizzas com bordas e complementos",
  "pos": "PDV",
  "internet_connection_lost": "Sua conexão com a internet foi perdida, verifique sua conexão.",
  "internnet_connection_reestablished": "Sua conexão com a internet foi reestabelecida.",
  "register_your_customers_quickly_easily": "Faça o cadastro dos seus clientes e realize pedidos de maneira ainda mais ágil e descomplicada.",
  "repeat_your_customer_clicks": "Repita o último pedido do seu cliente em apenas 2 cliques.",
  "cash_register_simply": "Abertura e fechamento de caixa com relatórios simplesmente fantásticos.",
  "gain_extra_speed": "Ganhe uma velocidade extra no atendimento das mesas.",
  "support_more_information": "A mensalidade do seu plano completo venceu e não identificamos o pagamento. Entre em contato com o suporte para mais informações",
  "dear_customer": "Prezado Cliente,",
  "Nenhum garçom ativo": "Ver Cardápio",
  "have": "têm",
  "not_have": "não têm",
  "new_orders": "novos pedidos.",
  "go_to_orders": "Ir para pedidos",
  "general_settings": "Configurações Gerais",
  "need_help": "Precisando de Ajuda?",
  "you": "Você",
  "copied_clipboard": "Copiado para área de transferência",
  "copy_n": "Copiar",
  "browser_not_have_copy": "Seu navegador não possuí a opção de copiar",
  "cashback_status": "Status Cashback",
  "cashback_configuration": "Configuração Cashback",
  "loyalty": "Fidelidade",
  "percentage_of": "Porcentagem",
  "percentage_up": "PORCENTAGEM",
  "validity": "Validade",
  "days_n": "Dias",
  "in_days": "Em dias",
  "how_to_configure_cashback": "Como configurar o Cashback no WhatsMenu",
  "activate_cashback": "Ativar Cashback",
  "promotion_link": "Link de divulgação",
  "profile_information": "Informações do Perfil",
  "store_name": "Nome da Loja",
  "enter_store_name": "Digite o nome da Loja",
  "enter_slug": "Digite o seu slug",
  "contact_phone_number": "Telefone de Contato",
  "enter_contact_phone": "Digite o telefone de contato",
  "contact": "Contato",
  "message_phone_number_will": "Este telefone só ficará disponível para clientes que estiverem com pedidos em andamento.",
  "enter_store_description": "Digite a descrição de sua loja",
  "minimum_delivery_order": "Pedido Mínimo Entrega",
  "minimum_order_excluding_delivery": "Menor valor de um pedido, sem contar a taxa de entrega.",
  "minimum_order_pickup": "Pedido Mínimo Retirada no Local",
  "minimum_order_pickup_delivery": "Menor valor de um pedido, sem contar a taxa de entrega.",
  "preview_your_store": "Pré visualização da sua loja",
  "preview": "Pré Visualização",
  "open_n": "Aberto",
  "calculate": "Calcular",
  "store_color": "Cor da Loja",
  "wait_time": "Tempo de Espera",
  "establishment": "Estabelecimento",
  "store_layout": "Layout da Loja",
  "shipping": "Frete",
  "congratulations": "Parabéns",
  "message_few_steps_organized": "Em poucos passos você terá um atendimento ágil e organizado com o seu",
  "digital_menu": "Cardápio Digital",
  "basic_details_establishment": "Preencha agora os dados básicos do seu estabelecimento.",
  "filling_profile": "Preenchendo perfil",
  "restaurant_address": "Endereço do restaurante",
  "message_plan_not_include_delivery": "Seu plano atual não inclui a funcionalidade de Delivery ou de Encomendas/Agendamentos. Entre em contato com o suporte para mais Detalhes.",
  "closing_scheduled_time": "Fechamento fora do horário programado",
  "reopening_date": "Data de Reabertura",
  "time_zone": "Fuso Horário",
  "select_time_zone": "Selecione seu fuso horário",
  "now": "Agora",
  "add_business_hours": "Adicionar Horário de Funcionamento",
  "store": "Loja",
  "closed": "fechada",
  "open_a": "aberta",
  "monday": "Segunda",
  "tuesday": "Terça",
  "wednesday": "Quarta",
  "thursday": "Quinta",
  "friday": "Sexta",
  "saturday": "Sábado",
  "sunday": "Domingo",
  "delivery_fees": "Taxas de Entrega",
  "shipping_neighborhood": "Frete por bairro",
  "shipping_by_mi": "Frete por KM",
  "mile": "Quilômetro",
  "delivery_type_change_to": "Tipo de entrega alterado para",
  "loading": "Carregando...",
  "delivery_type": "Tipo de Entrega",
  "by_mile": "Por Quilômetro",
  "reset_fees": "Redefinir Taxas",
  "message_fee_deleted": "Se você alterar a forma de taxa todas as taxas atuais serão apagadas",
  "message_really_change_fee": "Deseja realmente alterar a forma de taxa",
  "by_neighborhood": "Por Bairro",
  "neighborhood_name": "Nome do Bairro",
  "neighborhood_cannot_duplicated": "O bairro não pode ser repetido",
  "to_be_confirmed": "A consultar",
  "neighborhood_added": "Bairro adicionado",
  "city_and_neighborhood_added": "Cidade e bairro adicionados",
  "neighborhood_deleted": "Bairro excluído",
  "city_deleted": "Cidade  excluída",
  "time_minutes": "Tempo em Minutos",
  "changes_made_successfully": "Alterações feitas com sucesso",
  "unexpected_try_again": "Algo inesperado aconteceu. Por favor tente novamente",
  "success": "Sucesso",
  "online_payment_automatic": "Pagamento Online - Automático",
  "message_avoid_fraud_errors": "Evite golpes, erros e atrasos no atendimento tendo que verificar comprovantes no app do banco.",
  "asaas_terms_use": "Termos de uso Banco Asaas",
  "acess_email": "Acesse o email",
  "message_open_asaas_email": "Abra o email do Asaas e clique no link para criar sua senha bancaria.",
  "message_sent_sms_token": "Enviamos o SMS com o token para",
  "account_creation_details": "Dados da Conta Criada",
  "branch": "Agência",
  "acess_asaas_account": "Acesse sua conta Asaas",
  "termis_of_use": "Termo de uso",
  "online_payment": "Pagamento Online",
  "payment_on_delivery_card": "Pagamento na Entrega - Maquininha",
  "payment_on_delivery": "Pagamento na Entrega",
  "automate_your_payments": "Automatize o recebimento das suas cobranças",
  "activate_this": "Ativar Este",
  "up_two_business_days": "Até 2 dias úteis",
  "acess_detailed_statements_asaas": "Obs. acesse o seu app Banco Asaas para extrato detalhado.",
  "enable_surcharge_discount": "Habilitar Acréscimo/Desconto",
  "disabled_o": "Desativado",
  "activated": "Ativado",
  "card_network": "Bandeira",
  "add_card_network": "Adicionar Bandeira",
  "key_type": "Tipo de Chave",
  "key": "Chave",
  "invalid_pix": "Twint inválido",
  "money": "Dinheiro",
  "pix": "Twint",
  "picpay": "PicPay",
  "credit_card": "Cartão de Crédito",
  "credit": "Crédito",
  "debit": "Débito",
  "meal_voucher": "Vale Refeição",
  "food_voucher": "Vale Alimentação",
  "month": "Mês",
  "year": "Ano",
  "january": "Janeiro",
  "february": "Fevereiro",
  "march": "Março",
  "april": "Abril",
  "may": "Maio",
  "june": "Junho",
  "july": "Julho",
  "august": "Agosto",
  "september": "Setembro",
  "october": "Outubro",
  "november": "Novembro",
  "december": "Dezembro",
  "search_for": "Buscar por",
  "summary": "Resumo",
  "number_orders": "Quantidade de Pedidos",
  "no_fees": "Sem Taxas",
  "deliverys": "Entregas",
  "number_deliveries": "Quantidade de Entregas",
  "total_sales_and_coupons": "Total de vendas do período - Total de cupons",
  "period": "Período",
  "total_canceled": "Total cancelado",
  "number_canceled_orders": "Quantidade de Pedidos Cancelados",
  "total_fees": "Total de taxas",
  "no_fees_period": "Sem taxas nesse período",
  "fixed_amount": "VALOR FIXO",
  "fixed_amount_n": "Valor Fixo",
  "payment_methods_n": "Formas de Pagamento",
  "waitstaff": "Garçons",
  "creation_date": "Data Criação",
  "daily_report": "Relatório Diário",
  "monthly_report": "Relatório Mensal",
  "delivery_registration": "Cadastro de Entregadores",
  "error_deleting_delivery_person": "Erro ao excluir motoboy",
  "error_pausing_activating": "Erro ao pausar/ativar motoboy",
  "error_editing_delivery_person": "Error ao editar entregador",
  "delivery_person_name": "Nome do Entregador",
  "enter_delivery_person": "Digite o nome do Entregador",
  "enter_whatsapp_number": "Digite número do WhatsApp",
  "registered_delivery": "Entregadores Cadastrados",
  "fill_all_fields": "Por favor, preencha todos os campos.",
  "coupon_management": "Gestão de Cupons",
  "coupon_code": "Código do Cupom",
  "coupon_already_exists": "Este cupom já existe",
  "offer_type": "Tipo de Oferta",
  "coupon_type": "Tipo de Cupom",
  "first_purchase": "Primeira Compra",
  "discount_amount": "Valor de Desconto",
  "minimum_amount": "Valor Mínimo",
  "code": "Código",
  "unable_create_the_coupon": "Não foi possivel criar o cupom",
  "coupon_not_created": "Cupom não criado.",
  "status_coupon": "Status Cupom",
  "actives": "ativos",
  "for_orders": "para pedidos",
  "inactive": "desativados",
  "successfully_deleted": "excluído com sucesso",
  "delete_coupon": "excluído com sucesso",
  "business_offer_deliveries": "O seu negócio faz entregas",
  "customer_pick_up_order_onsite": "O cliente pode retirar o pedido no local",
  "cash_register": "Caixa",
  "active_cash_management": "Ativar gerenciamento de caixa",
  "message_currently_open_cash": "Você tem caixas abertos no momento. Por favor, os encerre no",
  "before_register_management": "antes de alterar o gerenciamento de caixas",
  "customers": "Clientes",
  "display_date_birth": "Exibir Data de Nascimento",
  "require_date_birth": "Obrigar data de nascimento",
  "default_texts": "Textos Padrões",
  "changing_default_text_observations": "Alterando texto padrão de observações",
  "product_note": "Observação Produto",
  "example_no_mayonnaise": "Ex.: Sem maionese, sem salada, etc...",
  "pizza_note": "Observação Pizza",
  "leave_any_note_product_here": "Deixe aqui qualquer observação no produto.",
  "message_client": "Olá [NOME], Tudo Bem?",
  "status_received": "Status Recebido",
  "you_order_production": "[NOME] seu pedido já está em produção!",
  "status_delivering": "Status Entregando",
  "yay_order_already_way": "Obaaa, [NOME] seu pedido já está a caminho!",
  "status_pickup": "Status Retirar",
  "order_ready_pickup": "Obaaa, [NOME] seu pedido já está pronto para retirada!",
  "order_status_pos": "Status pedido no PDV",
  "order_successfully_placed": "[NOME] pedido efetuado com sucesso, acompanhe o status do seu pedido abaixo!",
  "greeting_message": "Mensagem de saudação",
  "message_hello": "Olá [NOME]!",
  "welcome_to": "Seja bem vindo ao",
  "check_menu_order": "Veja o nosso cardápio para fazer seu pedido",
  "exclusive_offers": "Ofertas exclusivas para pedidos no link",
  "team": "Equipe",
  "use_a_printer": "Usar impressora",
  "group_order_printing": "Agrupar itens de pedidos para impressão",
  "print_test": "Teste Impressão",
  "test_print": "Testar Impressão",
  "printer_driver": "Driver de Impressão",
  "others": "Outros",
  "printing_app": "Aplicativo de Impressão",
  "print_width": "Largura da Impressão",
  "letters": "Letras",
  "small": "Pequena",
  "large": "Grande",
  "copies": "Cópias",
  "necessary_whatsmenu_android_installed": "Necessário ter o aplicativo Whatsmenu Impressora 2.0 instalado (Somente Android)",
  "pizza_options": "Opções de Pizzas",
  "assign_price_most_expensive": "Atribuir valor da mais cara",
  "different_crusts_each_flavor": "Permitir bordas para cada sabor",
  "allow_each_flavor": "Permitir complementos para cada sabor",
  "stock_options": "Opções de Estoque",
  "display_out_of_stock_product_addons": "Exibir status de 'esgotado' em produtos e complementos pausados",
  "hide_ssn_receipt": "Ocultar opção de AVS na nota",
  "description_top_product_page": "Exibir foto e descrição do produto no topo da página do produto",
  "activate_catalog_mode_delivery": "Ativar modo catálogo na delivery - Não será possível finalizar a compra, apenas visualizar os produtos.",
  "activate_catalog_the_table": "Ativar modo catálogo na mesa - Não será possível finalizar a compra, apenas visualizar os produtos.",
  "automations": "Automações",
  "send_status_page_pos_customer": "Deseja enviar a página de Status no PDV para o cliente",
  "only_indentification": "Apenas a identificação",
  "label": "Rótulo",
  "saving": "Salvando...",
  "are_no_pending_changes": "Não há alterações pendentes",
  "no_changes": "Sem Alterações",
  "saved": "Salvo",
  "saved_successfully": "Salvo com sucesso.",
  "order_date": "Data Pedido",
  "tomorrow": "Amanhã",
  "today": "Hoje",
  "deliver_pickup": "Entregando/Retirada",
  "delivery_e": "Entrega Delivery",
  "closed_o": "Fechado",
  "receive_orders_from": "Para receber pedidos de",
  "enable_options_the_settings_for": "habilite a opções nas configurações de",
  "printed": "Impr",
  "are_no_orders_moment": "Não há pedidos até o momento",
  "hours": "Horários",
  "review_delivery_hours": "Por favor, revise o horário de entregas.",
  "changes_saved": "Alterações salvas com sucesso.",
  "not_saved": "Não Salvo",
  "unable_save_check_connection": "Não foi possível salvar as alterações, vefique a sua conexão.",
  "no_changes_to_save": "Não há alterações para serem salvas",
  "delivery_type_activated": "É necessário ter no mínimo um tipo de entrega ativado",
  "pickups": "Retiradas",
  "deliveries_pickups": "Entregas/Retiradas",
  "delivery_types_must_one_activated": "Tipos de entregas precisam ter no mínimo um ativado",
  "activate_orders": "Ativar Encomendas",
  "delivery_scheduling": "Agendamentos de entregas",
  "order_settings": "Configurações de Encomendas",
  "pickup_onsite": "Retirar no Local",
  "allow_onesite_pickups": "Permitir Retiradas no Local",
  "allow_delivery_services": "Permitir Entregas Delivery",
  "make_available": "Disponibilizar",
  "starting_from": "a partir de",
  "allow": "Permitir",
  "for_day": "para o dia",
  "within": "em até",
  "group": "Agrupar",
  "every": "a cada",
  "minutes": "Minutos",
  "max_of": "Máx. de",
  "per_day_from": "por dia de 1 a 1000",
  "per_hour": "por horário",
  "display_menu_as": "Exibir no cardápio como",
  "minimum_for_delivery": "Mínimo para entrega",
  "minimum_for_pickup": "Mínimo para retirada",
  "register_order_cash_register": "Registrar Pedidos no caixa",
  "on_day_ordered": "No dia em que foi pedido",
  "on_delivery_day": "No dia de entrega",
  "availability_of": "Disponibilidade de",
  "dates_not_be_available": "Datas que você não fará",
  "select_special_dates": "Selecione as datas especiais que você não não fará",
  "select_a_date": "Selecione uma data",
  "defined_dates": "Datas Definidas",
  "remove": "Remover",
  "values": "Valores",
  "minimum_order_for_pickup": "Pedido Mínimo Retirar no Local",
  "table_name_already_exists": "Ja existe uma mesa com esse nome",
  "create_table": "Criar Mesa",
  "enter_valid_name": "Por favor insira um nome válido",
  "new_table": "Nova mesa",
  "creating_table": "Criando mesa",
  "using_table_system": "Utilizando sistema de mesas",
  "table_name": "Nome da Mesa",
  "cancel": "Cancelar",
  "available": "Disponível",
  "occupied": "Ocupada",
  "paused_a": "Pausada",
  "selected": "Selecionada",
  "you_haven_registered_tables": "Você ainda não cadastrou nenhuma mesa",
  "how_close_entire_table": "Como encerrar a mesa total",
  "how_close_individual": "Como encerrar comandas  individuais",
  "table_settings": "Configurações da Mesa",
  "fee_name_already_exists": "Já existe uma taxa com este nome.",
  "fee_name_cannot_empty": "Nome da taxa não pode ser vázio.",
  "enter_value_greater_than": "Insira um valor maior que",
  "add_fee": "Adicionar Taxa",
  "created_successfully_a": "criada com sucesso",
  "activate_fee": "Ativar Taxa",
  "automatic_charge": "Cobrança Automática",
  "changed_successfully": "alterado com sucesso",
  "delete_fee": "Excluir Taxa",
  "deleted_successfuly": "excluído com sucesso",
  "register_waitstaff": "Registrar Garçom",
  "update_waitstaff": "Atualizar Garçom",
  "create_waitstaff": "Criar Garçom",
  "edit_waitstaff": "Editar Garçom",
  "delete_waitstaff": "Deletar Garçom",
  "deleted_successfully_o": "excluido com sucesso",
  "restrict_menu": "Restringir Cardápio",
  "unable_retrieve_menu_information": "Não foi possivel obter informações do cardápio no momento, tente novamente",
  "creating_fees": "Criando taxas",
  "fee_name": "Nome da taxa",
  "fee_already_exists": "Esta taxa já existe",
  "type_charge": "Tipo de Cobrança",
  "enter_number_greater_than": "Insira um número maior que",
  "charge_automatically": "Cobrar Automaticamente",
  "deactive": "Desativar",
  "registering_waitstaff": "Cadastrando garçom",
  "placing_order_waiter": "Fazendo um pedido como garçom",
  "placing_pizza_order_waiter": "Fazendo um pedido de pizza como garçom",
  "waitstaff_name": "Nome do Garçom",
  "permission": "Permissão",
  "manager": "Gerente",
  "allow_orders": "Permitir Pedidos",
  "register": "Registrar",
  "categories": "Categorias",
  "select_categories_waiter_permitted": "Selecione quais categorias o garçom terá permissão para realizar pedidos",
  "call_waitstaff": "Chamar Garçom",
  "keep_waitstaff_logged": "Manter garçom logado",
  "change_password": "Alterar senha",
  "change_table": "Alterar Mesa",
  "enter_valid_name_different_current": "Por favor insira um nome válido ou diferente do nome atual",
  "delete_table": "Excluir Mesa",
  "activated_a": "ativada",
  "pause_table": "Pausar Mesa",
  "not_possible_pause_table_open": "Não é possivel pausar mesas com comandas abertas",
  "table_options_up": "OPÇÕES DE MESA",
  "points": "Pontos",
  "settings_up": "CONFIGURAÇÕES",
  "background": "Fundo",
  "resources": "Recursos",
  "register_cashier": "Registrar Operador de Caixa",
  "registering_operator": "Cadastrando operador",
  "placing_order_operator": "Fazendo um pedido como operador",
  "operator_name": "Nome do Operador",
  "operators": "Operadores",
  "box_settings": "Configurações de Caixa",
  "delete_operator": "Deletar Operador",
  "edit_operator": "Editar Operador",
  "create_operator": "Criar Operador",
  "my_name_is": "Meu nome é",
  "update_operator": "Atualizar Operador",
  "register_operator": "Registrar Operador",
  "there_failure_transaction": "Houve uma falha na transação",
  "change_card": "Alterar Cartão",
  "unable_change_payment_card": "Não foi possível alterar o cartão de cobrança",
  "redirecting_payment_page": "Redirecionando para Pagina de Pagamento",
  "difficulty_generating_payment_screen": "Estamos com dificuldade para gerar a tela de pagamento, tente novamente",
  "payment_gateway": "Gateway de Pagamento",
  "no_payment_gateway_found": "Não encontramos nenhum gateway de pagamento",
  "select_option": "Selecione uma opção",
  "subscription": "Assinatura",
  "adding_your_subscription": "Estamos adicionando sua assinatura.",
  "failure_adding_your_subscription": "Houve uma falha ao adicionar sua assinatura",
  "subscription_created_successfully": "Sua assinatura foi criada com sucesso",
  "failure_processing_try_again": "Houve uma falha no processamento tente novamente",
  "ongoing_monthly_invoice": "Existe uma fatura mensal em andamento.",
  "subscription_canceled": "Sua assinatura esta cancelada",
  "required": "Obrigatório",
  "additional": "Adicional",
  "monthly": "Mensal",
  "annual": "Anual",
  "semiannual": "Semestral",
  "monthly_payment": "Mensalidade no cartão",
  "add_registration_service": "Adicionar serviço de cadastro",
  "with_printer": "Com impressora",
  "message_failure_payment_card": "Houve uma falha no processamento do pagamento verifique os dados e certifique-se que existe limite disponível em seu cartão",
  "payment_completed_successfully": "Pagamento efetuado com sucesso, iremos recarregar a pagina.",
  "outstanding_invoices": "Faturas em Aberto",
  "in_progress": "Em Andamento",
  "pay": "Pagar",
  "view_items": "Ver Itens",
  "cart": "Carrinho",
  "check_items": "Confira os itens",
  "start_registration": "Começar Cadastro",
  "proceeding_whatsmenu_contact": "Ao prosseguir, aceito que o WhatsMenu entre em contato comigo por telefone, e-mail ou WhatsApp (incluindo mensagens automáticas para fins comerciais).",
  "owner_name": "Nome do dono do estabelecimento",
  "there_no_items_moment": "Não há itens no momento",
  "unique": "Única",
  "hello": "Olá",
  "after_payment_confirmation": "após a confirmação de pagamento você será redirecionado a página será recarregada.",
  "proceed_payment": "Continuar para Pagamento",
  "pending_pay": "Pendente",
  "refunded": "Estornado",
  "under_review": "Em Analise",
  "awaiting_confirmation": "Aguardando Confirmação",
  "your_monthly_every_day": "Sua Mensalidade vence todo dia",
  "each_month": "de cada mês",
  "services": "Serviços",
  "plan": "Plano",
  "generate_invoice": "Gerar Boleto",
  "no_outstandigin_invoices_moment": "Não há faturas em aberto no momento.",
  "paid_invoice_history": "Histórico de Faturas Pagas",
  "detail_table": "Detalhar Mesa",
  "order_results": "Resultados Pedido",
  "displaying_orders_date_from": "Exibindo pedidos por data de",
  "domain_registered_dns_registration": "Domínio registrado com sucesso!, entre em contato com o suporte para solicitar o cadastro de registro de DNS",
  "domain_registered": "Domínio registrado com sucesso!",
  "domain_already_registered": "Esse dominio ja esta registrado",
  "enter_valid_domain": "Por favor insira um domínio válido",
  "invalid_domain": "Domínio Inválido",
  "domain_not_valid": "Este domínio não é válido por favor insira um dominio válido",
  "domain_successfully_deleted": "Domínio excluído com sucesso",
  "delete_domain": "Excluir Domínio",
  "default_domain_successfully_changed": "Domínio padrão alterado com sucesso",
  "dns_entry": "Entrada de DNS",
  "dns_entry_successfully": "Entrada de DNS excluída com sucesso",
  "domain_settings": "Configurações de Domínios",
  "after_registering_domain": "Após o cadastro do domínio será necessário realizar o apontamento para esses servidores",
  "domain": "Domínio",
  "domain_information": "Dados do Domínio",
  "registered_domains": "Domínios Cadastrados",
  "default_domain_cannot_deleted": "Domínio padrão não pode ser excluído. Por favor entre em contato com o suporte",
  "domain_com": "dominio.com",
  "default_domain": "Domínio padrão",
  "monthly_orders_report": "Relatório de Pedidos Mensais",
  "monthlys": "Mensais",
  "daily_orders_report": "Relatório de Pedidos Diários",
  "box_closures": "Encerramentos de Caixas",
  "box_report": "Relatório de Caixas",
  "customer_report_v2": "Relatório de Clientes",
  "best_selling_report": "Relatório de Mais Vendidos",
  "best_sellers_v2": "Os Mais Vendidos",
  "click_here_view_details": "Clique aqui para ver o detalhado",
  "summary_last_months": "Resumo dos últimos 12 meses",
  "summary_last_days": "Resumo dos últimos 30 dias",
  "financial": "Financeiro",
  "ph": "Tel",
  "dailys": "Diários",
  "opening": "Abertura",
  "export_spreadsheet": "Exportar para planilha",
  "creation": "Criação",
  "delivery_m": "entrega",
  "ready": "Pronto",
  "spreadsheet": "Planilha",
  "successfully_generated": "gerada com sucesso",
  "no_orders_found": "Nenhum pedido encontrado",
  "empty": "Vazio",
  "outputs": "Saidas",
  "daily": "Diário",
  "operators_boxes": "Operadadores caixas",
  "box_listing": "Listagem de Caixas",
  "initial_balance": "Saldo inicial",
  "opening_date": "Data de Abertura",
  "closing_date": "Data de encerramento",
  "box_closure": "Encerramento de Caixas",
  "paid_a": "Paga",
  "view_menu": "Ver Menu",
  "food": "Vale Refeição",
  "snack": "Vale Alimentação",
  "coin_t": "Moeda",
  "order_printed_please_wait_finish": "Um pedido esta sendo impresso, aguarde a finalização",
  "password_settings": "Configurações de senha",
  "access": "acesso",
  "security": "segurança",
  "incorrect_current_password": "Senha atual incorreta",
  "register_financial_password": "Cadastre uma senha financeira",
  "register_password": "Cadastre senha",
  "change_financial_password": "Alterar Senha Financeira",
  "register_password_financial": "Cadastrar Senha Financeira",
  "current_password": "Senha Atual",
  "new_password": "Nova Senha",
  "confirm_password": "Confirme a Senha",
  "change_access_password": "Alterar Senha de Acesso",
  "recover_financial_password": "Recuperar Senha Financeira",
  "confirm_new_password": "Confirmar Nova Senha",
  "authentication_failure": "Falha na autenticação",
  "password_recovery_token": "Token de recuperação de senha expirado ou ínvalido",
  "use_printer": "Usar Impressora",
  "enable_stock_control": "Habilitar Controle de Estoque",
  "stock_control": "Controle de Estoque",
  "layout_options": "Opções de Layout",
  "security_password_registered": "Senha de segurança cadastrada com sucesso",
  "with": "com",
  "consult": "Consultar",
  "link_for_order_status": "Link para o status do pedido",
  "chosen_date_earlier_current_date": "A data escolhida é menor que a data atual, favor escolher uma data maior.",
  "check_the_cvv": "Verifique o CVV",
  "check_card_details": "Verifique os dados do cartão",
  "check_billing_address": "Verifique o endereço de cobrança",
  "check_card_expiration_date": "Verifique a validade do cartão",
  "check_card_number": "Verifique o número do cartão",
  "check_security_code": "Verifique o código de segurança",
  "check_code": "Verifique o NPA",
  "unable_register_your_order_try_again": "Não foi possível registrar seu pedido tente novamente",
  "system_identified_you_are_outside": "O sistema identificou que você está fora da nossa área de cobertura, tente concluir o seu pedido novamente",
  "swiss_french": "Français suisse (Fr.)",
  "swiss_french_chf": "Français suisse",
  "set_the_environment": "Defina o ambiente",
  "origin": "Origem",
  "not_received": "Não recebido",
  "marketing_and_sales": "Marketing e Vendas",
  "we_integrate_your_store": "Integramos sua loja as maiores ferramentas de marketing e vendas do mercado, atraia mais clientes e venda muito mais com menos trabalho.",
  "own_website": "Site Próprio",
  "disponibility": "Disponibilidade",
  "please_enter_valid_name": "Por favor insira um nome válido!",
  "image_up": "Imagem com até 8mb (resolução recomendada de 600x450)",
  "share": "Compartilhar",
  "fill_all_required": "Preencha todos os campos obrigatórios",
  "billing": "Faturamento",
  "result_orders": "Resultado Pedidos",
  "viewing_orders_by_date": "Exibindo pedidos por data de",
  "enable_original_image_size": "Ativar tamanho original da imagem",
  "cashback": "Cashback",
  "no_register": "Sem cadastro",
  "load_complements_wait": "Carregando complementos, por favor aguarde",
  "deactivated_delivery": "Desativar Delivery",
  "hide_unity": "Desabilitar quantidade",
  "Delivery_options": "Opções do Delivery",
  "Profile_options": "Opções do Perfil",
  "catalog_mode": "Modo de Catálogo",
  "status_order": "Estado da encomenda",
  "completed": "Concluído",
  "slug": "Slug",
  "clone_categories": "Cloner les catégories",
  "clone": "Cloner",
  "no_change_needed": "Não precisa de troco",
  "serivce_fee": "Taxa de Serviço",
  "customer_delivery_address": "Endereço de Entrega do Cliente",
  "not_informed": "Não informado",
  "route_up": "ROTA",
  "payments_in": "Pagamentos em",
  "the_order_is_now_ready": "o pedido já está pronto para ser retirado! Aqui está o contato do cliente",
  "if_you_need": "caso precise",
  "reference": "Referência",
  "pickup_the_location": "Vou retirar no local",
  "table_request": "Pedido mesa",
  "possible": "Possível",
  "the_date_of_your_delivery": "A data da sua entrega é",

  "whatsMenuPro": {
    "this_app_is_better_than": "This APP is better than employees whether in-store or for delivery, all management is now automated!",
    "waiter_app_delivery": "WAITER APP, DELIVERY ROUTE, AND MUCH MORE.",
    "yes_i_want": "YES, I WANT MORE INFORMATION",
    "i_want_information": "I WANT INFORMATION!",
    "aprroved_by": "Approved by over",
    "restaurants_worldwide": "restaurants worldwide",
    "more_professionalism": "More professionalism",
    "more_clients_sales": "More clients and more sales...",
    "whatsMenuPro_transform_small": "WhatsMenu.PRO transforms small restaurants into powerful sales machines:",
    "receive_orders_auto": "Receive orders automatically without interacting with customers.",
    "customers_will_not": "Customers will no longer have to wait for the attendant.",
    "no_extra_labor": "No extra labor required to take orders.",
    "automatic_priting": "Automatic printing of orders.",
    "never_lose_order": "Never lose an order again.",
    "reduce_to_zero": "Reduce order errors to zero.",
    "increase_the_average": "Increase the average ticket and purchase frequency.",
    "control_everything": "Control everything automatically on your phone or computer.",
    "the_features": "The features you'll receive.",
    "the_most_complet": "A mais completa e poderosa Plataforma de Restaurantes:",
    "virtual_attendant": "Virtual Attendant",
    "catalog_mode": "Catalog Mode",
    "orders_delivery_scheduling": "Orders, Delivery Scheduling",
    "counter_orders": "Counter orders",
    "order_manager": "Order Manager",
    "pos_point_of_sale": "POS - Point of Sale",
    "reports_customers": "Reports: Customers, Products, Revenue, Inventory",
    "qr_code_table_delivery": "QR Code for Table, Delivery, and Counter",
    "free_website": "Free Professional Website",
    "app_waiter": "APP Waiter",
    "delivery_manager": "Delivery manager",
    "automatic_inventory_control": "Automatic Inventory Control",
    "domain_hosting": "Domain hosting",
    "talk_to_attendant": "TALK TO THE ATTENDANT",
    "those_who_use": "Those who use it recommend",
    "see_the_models": "See the models and place",
    "test_order": "a test oder.",
    "talk_with_attendant": "Talk with the attendant",
    "still_afraid": "But I'm still afraid",
    "wont_work": "it won't work, what now?",
    "we_have_human_team": "We have a 100% human team, highly trained to help you with these first steps, and whenever you need. We will schedule an onboarding video call and assist you with the settings. We'll guide you every step of the way!",
    "yes_i_wanna_help": "YES, I WANT HELP",
    "days_of_satisfaction": "7 days of satisfaction",
    "or_your_money_back": "or your money back!",
    "i_belive_in_the_results": "I believe so much in the results that the WhatsMenu.PRO platform will bring you that I offer a 7-day guarantee for you to try it out. If you're not satisfied with the results or if you regret it for any other reason, just contact me on WhatsApp, and I'll refund 100% of your money.",
    "talk_with_us_on_WhatsApp": "Talk with us on WhatsApp",
    "what_is_your_name": "What is your name?",
    "what_is_your_WhatsApp": "What is your WhatsApp?",
    "what_product_you_sell": "What product do you sell?",
    "Browse": "Navegue",
    "how_works": "HOW IT WORKS",
    "features": "FEATURES",
    "clients": "CLIENTS",
    "examples": "EXAMPLES",
    "team": "TEAM",
    "support": "SUPPORT",
    "access_dashboard": "ACCESS DASHBOARD",
    "right_reserved": "All rights reserved.",
    "digital_menu": "DIGITAL MENU",
    "delivery": "DELIVERY",
    "call": "CALL NOW!",
    "sounds_notification": "Som de notificação de pedidos",
    "play_sound_delivery": "Ativar som de pedidos de delivery",
    "play_sound_table": "Ativar som de pedidos de mesa",
    "play_sound_package": "Ativar som de pedidos de encomenda"
  }
}
